import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';
import styles from '@/styles/header/BaseNavigation.module.scss';

import { NavigationItem as NavigationItemDefaultProps } from "@incarail/killa-common";
import { ReactNode, useState } from "react";
import { BaseFooterNavigationItemSubItem } from "./BaseFooterNavigationItemSubItem";
import { BaseFooterNavigationItemLink } from './BaseFooterNavigationItemLink';

export const BaseFooterNavigationItemSub: React.FC<NavigationItemDefaultProps> = (ni) => {

  const [showSubNavOnMobile, setShowNavOnMobile] = useState(false);

  return (
    <li>
      <BaseFooterNavigationItemLink {...ni} />
      {ni?.fields?.children?.length > 0 && (
        <ul className={showSubNavOnMobile ? 'd-flex' : ''}>
          {ni?.fields?.children.reduce((nl: ReactNode[], ni: NavigationItemDefaultProps) => {
            nl.push(<BaseFooterNavigationItemSubItem key={ni.sys.id} {...ni} />);
            return nl;
          }, [])}
        </ul>
      )}
    </li>
  )
}