import { useState } from "react";
import { Tab as TabDefaultProps } from "@incarail/killa-common";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';

import styles from './Tabs.module.scss'

export const AccordionItem = (props: TabDefaultProps) => {

  const { headline, content } = props;

  const [showTabContent, setShowTabContent] = useState(false);

  return (
    <div className={[styles.AccordionItem, (showTabContent ? styles.TabOpen : '')].join(' ')}>
      <button type="button" className={styles.TabHead} onClick={() => {
        setShowTabContent(!showTabContent);
      }}>
        <div className={styles.TabHeadline}>
          {headline}
        </div>
        <IconChevronDown />
      </button>
      <div className={styles.TabContent}>
        {content && documentToReactComponents(content)}
      </div>
    </div>
  );
};