import { HTMLContent as HTMLContentDefaultProps } from "@incarail/killa-common";
import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';
import styles from './HTMLContent.module.scss';
import { DynamicForm } from '../Form';

export const HTMLContent = (props: HTMLContentDefaultProps) => {
  const { sys, html, js = '', dependencies = { stylesheets: [], javascripts: [] } } = props;
  const [content, setContent] = useState<React.ReactNode | null>(null);

  const executeScriptContentSafely = (scriptContent: string) => {
    try {
      new Function(scriptContent)();
    } catch (error) {
      console.error("Error executing script content:", error);
    }
  };

  const loadScript = (scriptObj: { name: string; url: string; depends?: string }): Promise<string> => {
    return new Promise((resolve, reject) => {
      const existingScript = document.querySelector(`script[src="${scriptObj.url}"]`);
      
      if (existingScript) {
        existingScript.remove();
      }
  
      // Crear y cargar el nuevo script
      const script = document.createElement('script');
      script.src = scriptObj.url;
      script.async = true;
  
      script.onload = () => {
        resolve(scriptObj.name);
      };
  
      script.onerror = () => {
        reject(new Error(`Error loading script: ${scriptObj.name}`));
      };
  
      document.head.appendChild(script);
    });
  };
  

  const loadScriptsWithDependencies = async () => {
    const loadedScripts = new Set<string>();
    const scriptsWithoutDeps = dependencies?.javascripts?.filter(script => !script.depends);

    if (scriptsWithoutDeps) {
      await Promise.all(scriptsWithoutDeps.map(script => loadScript(script).then(loaded => loadedScripts.add(loaded))));
    }

    const scriptsWithDeps = dependencies?.javascripts?.filter(script => script.depends);

    while (scriptsWithDeps && scriptsWithDeps.length > 0) {
      const remainingScripts: typeof scriptsWithDeps = [];

      for (const script of scriptsWithDeps) {
        if (loadedScripts.has(script.depends!)) {
          await loadScript(script).then(loaded => loadedScripts.add(loaded));
        } else {
          remainingScripts.push(script);
        }
      }

      if (remainingScripts.length === scriptsWithDeps.length) {
        console.error("Ciclo de dependencias o scripts faltantes");
        break;
      }

      scriptsWithDeps.length = 0;
      scriptsWithDeps.push(...remainingScripts);
    }

    return Promise.resolve();
  };
  
  useEffect(() => {
    const loadDependenciesAndExecuteJS = async () => {
      await loadScriptsWithDependencies();

      if (js) {
        executeScriptContentSafely(js);
      }
    };

    loadDependenciesAndExecuteJS();
  }, [js, dependencies]);


  useEffect(() => {
    const reactElements = parse(html, {
      replace: domNode => {
        if (domNode.type === 'tag') {          
          if (domNode.name === 'dynamicform') {
            const dynamicFormProps = {
              id: domNode?.attribs?.id  ?? '',
              headline: domNode?.attribs?.headline ?? '',
              tagline: domNode?.attribs?.tagline ?? '',
              formType: domNode?.attribs?.formtype ?? '',
              submitText: domNode?.attribs?.submittext ?? '',
              submitPushUrl: domNode?.attribs?.submitpushurl ?? '',
              ...domNode.attribs,
            };            
            return <DynamicForm {...dynamicFormProps} />;
          }
        }
      }
    });

    setContent(reactElements);
  }, [html]);

  return (
    <>
      <div key={sys.id} className={styles.HTMLContent}>
        {content}
      </div>
    </>
  );
};
