import { useEffect, useState } from "react";
import { useTranslation } from 'next-i18next';
import DatePicker from "react-datepicker";
import CloseButton from '@/assets/icons/close.svg';
import IconCalendar from '@/assets/icons/calendar.svg';
import { isMobile } from "@/utils/shared/app/isMobile";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Select.module.scss";
import { addDays, addYears, endOfYear, format } from "date-fns";
import { es, enUS } from 'date-fns/locale';
import { useRouter } from "next/router";
import { useBookerContext } from '@/context/BookerContext';

interface SelectOptionsDefaultProps {
  from: Date,
  to?: Date | null,
}

interface SelectDefaultProps {
  className?: string,
  label?: string,
  isRoundTrip: boolean,
  fromDate?: Date,
  toDate?: Date,
  excludeDates?: Date[],
  onSelect: (option: SelectOptionsDefaultProps) => void,
  enabledFrom?: Date,
  enabledTo?: Date,
}

export const SelectDate = (props: SelectDefaultProps) => {

  const { t } = useTranslation('form');
  const { deactivateBooker } = useBookerContext();

  const { label, isRoundTrip, className, fromDate, toDate, enabledFrom, enabledTo, excludeDates, onSelect } = props;


  const [startDate, setStartDate] = useState<Date | null>(fromDate ?? null);
  const [endDate, setEndDate] = useState<Date | null>(toDate ?? null);
  const [openSelect, setOpenSelect] = useState(false);
  const [selected, setSelected] = useState<SelectOptionsDefaultProps>({
    from: fromDate ?? addDays(Date.now(), 1),
    to: toDate ?? addDays(Date.now(), 1),
  });

  const { locale } = useRouter();

  const onChange = (dates: [Date, Date | null] | Date) => {
    if (isRoundTrip) {
      const [start, end] = dates as [Date, Date | null];
      setStartDate(start);
      setEndDate(end);
      setSelected({
        from: start,
        to: end
      });
      onSelect({
        from: start,
        to: end
      });
      if (!isRoundTrip || end) {
        setOpenSelect(false);
      }
    } else {
      const date = dates as Date;
      setStartDate(date);
      setEndDate(null);
      setSelected({
        from: date,
        to: null
      });
      onSelect({
        from: date,
        to: null
      });
      setOpenSelect(false);
    }
  };

  let closeTimer: NodeJS.Timeout;

  const handleClose = () => {
    if (isMobile) return;
    if (closeTimer) clearTimeout(closeTimer);
    closeTimer = setTimeout(() => {
      setOpenSelect(false);
      deactivateBooker();
    }, 1000);
  };

  const handleMouseEnter = () => {
    if (isMobile) return;
    if (closeTimer) clearTimeout(closeTimer);
  };

  useEffect(() => {
    if (!openSelect && isRoundTrip && startDate && !endDate) {
      setEndDate(startDate);
      setSelected({
        from: startDate as Date,
        to: startDate
      });
    }
  }, [openSelect, isRoundTrip, startDate, endDate]);

  return (
    <div
      className={[className ?? '', styles.Select, styles.SelectDate, !isRoundTrip ? styles.SelectDateOne : '', openSelect ? styles.SelectOpen : ''].join(' ').trim()}
      onMouseLeave={handleClose}
      onMouseEnter={handleMouseEnter}
    >
      {isRoundTrip && 
        <div className={styles.SelectDateRange}>
          <button
            type="button"
            className={['btn', styles.SelectButton].join(' ')}
            onClick={() => setOpenSelect(!openSelect)}
          >
            <IconCalendar />
            <span>
              {format(selected?.from, locale !== 'en' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}
            </span>
          </button>
          <button
            type="button"
            className={['btn', styles.SelectButton].join(' ')}
            onClick={() => setOpenSelect(!openSelect)}
          >
            <IconCalendar />
            <span>
              {selected?.to ? format(selected?.to, locale !== 'en' ? 'dd/MM/yyyy' : 'MM/dd/yyyy') : ''}
            </span>
          </button>
        </div>}
      {!isRoundTrip && 
        <button
          type="button"
          className={['btn', styles.SelectButton].join(' ')}
          onClick={() => setOpenSelect(!openSelect)}
        >
          <IconCalendar />
          <span>
          {format(selected?.from, locale !== 'en' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}
          </span>
        </button>}
      <div className={styles.SelectBack}>
        <div className={styles.SelectContent}>
          <div className={styles.SelectContentHeader}>
            <div className={styles.SelectContentHeaderLabel}>
              {label ?? t('chooseOption')}
            </div>
            <button
              type="button"
              className={['btn', styles.SelectContentClose].join(' ')}
              onClick={() => setOpenSelect(!openSelect)}
            >
              <CloseButton />
            </button>
          </div>
          <div className={styles.SelectDates}>
            <div className={styles.SelectDatesContent}>
              <DatePicker
                locale={locale !== 'en' ? es : enUS}
                dateFormat={locale !== 'en' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                selected={startDate}
                startDate={startDate}
                endDate={isRoundTrip ? endDate : startDate}
                onChange={onChange}
                minDate={addDays(Date.now(), 1)}
                maxDate={addYears(endOfYear(new Date()), 1)}
                monthsShown={isRoundTrip ? 2 : 1}
                selectsRange={isRoundTrip}
                excludeDates={excludeDates}
                inline
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};
