import { NavigationItem as NavigationItemDefaultProps } from "@incarail/killa-common";
import { ReactNode, useRef, useState, useEffect } from 'react';
import { BaseNavigationItemLink } from './BaseNavigationItemLink';
import { BaseNavigationItemSub } from "./BaseNavigationItemSub";

import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';
import styles from '@/styles/header/BaseNavigation.module.scss'
import { useTranslation } from 'next-i18next';
import { getContentfulImage } from "@/utils";
import Image from "next/image";

export const BaseNavigationItemLight: React.FC<NavigationItemDefaultProps> = (ni) => {

  const { t } = useTranslation('navigation');
  const [showSubNavMobile, setShowSubNavOnMobile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const refSubNav = useRef<HTMLSpanElement>(null);

  
  useEffect(() => {
    refSubNav?.current?.classList.toggle('d-flex', showSubNavMobile);
  }, [showSubNavMobile]);

  return (
    <li
      key={ni.sys.id}
      className={showMenu ? styles.SiteNavOpen : ''}
      onMouseEnter={() => {
        setShowMenu(!showMenu);
      }}
      onMouseLeave={() => {
        setShowMenu(!showMenu);
      }}
    >
      <BaseNavigationItemLink
       {...ni}
       onClick={() => setShowMenu(false)}
      />
      {ni?.fields?.children?.length > 0 && (
      <IconChevronDown
        className={styles.SiteNavIconMobile}
        onClick={() => {
          setShowSubNavOnMobile(!showSubNavMobile);
        }}
      />
      )}
      {ni?.fields?.children?.length > 0 && (
        <span className={[styles.SiteNavSub, ni?.fields?.poster ? styles.SiteNavSubPoster : ''].join(' ')} ref={refSubNav}>
          <span className={styles.SiteNavSubInner}>
            {ni.fields.children.length > 0 &&
            <ul>
              {ni.fields.children.reduce((nl: ReactNode[], ni: NavigationItemDefaultProps) => {
                nl.push(<BaseNavigationItemSub key={ni.sys.id} {...ni} />);
                return nl;
              }, [])}
            </ul>}
          </span>
          <span className={styles.SiteNavSubInnerPoster}>
            {ni.fields.poster && 
            <a href={ni.fields.posterLink ?? '#'}>
              <Image
                className="img-fluid"
                src={getContentfulImage(ni.fields.poster, { q: 75, fm: 'WEBP' }) ?? ''}
                alt={ni.fields.poster.fields.description}
                width={ni.fields.poster.fields.file.details.image?.width}
                height={ni.fields.poster.fields.file.details.image?.height}
                loading="lazy"
              />
            </a>
            }
          </span>
        </span>
      )}
    </li>
  )

}