
import { NavigationItem as NavigationItemDefaultProps } from "@incarail/killa-common";

import Link from 'next/link';

import IconOpenCross from '@/assets/icons/arrows/cross.svg';
import IconCloseLine from '@/assets/icons/arrows/line.svg';

import styles from '@/styles/footer/BaseFooter.module.scss'

type FooterNavigationItem = NavigationItemDefaultProps & {
  openHandler?: () => void;
}

export const BaseFooterNavigationItemLink: React.FC<FooterNavigationItem> = (ni) => {
  const { openHandler } = ni;

  return (
    <span className={styles.FooterSectionNavLink}>
      <a href={ni.fields.link} target={ni.fields.target !== 'Same Window' ? '_blank' : '_self'} rel={ni.fields.target !== 'Same Window' ? 'noreferrer' : ''} className={ni.fields.isHeadline ? styles.FooterSectionNavLinkTitle : ''}>
      {ni.fields.name}
      </a>
      {ni?.fields?.children?.length > 0 && (<>
        <IconOpenCross className={styles.FooterSectionNavSubOpen} onClick={openHandler} />
        <IconCloseLine className={styles.FooterSectionNavSubClose} onClick={openHandler} />
      </>)}
    </span>

  )
}