import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useField } from 'formik';

interface FormPhoneInputProps {
  name: string;
}

export const FormPhoneInput = (props: FormPhoneInputProps) => {
  const { name } = props;
  const [field, , helpers] = useField(name);

  const handleChange = (value: string | undefined) => {
    // Establecer el valor a un string vacío si el valor es undefined
    helpers.setValue(value || '');
  };

  return (
    <PhoneInput
      {...props}
      name={name}
      international
      countryCallingCodeEditable={false}
      defaultCountry="US"
      className="form-control"
      value={field.value || ''}
      onChange={handleChange}
    />
  );
};

export default FormPhoneInput;
