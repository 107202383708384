import { useCallback, useEffect, useRef, useState } from "react";
import { Booker as BookerDefaultProps, BookerJourneyTypesPropsConfig, BookerTicketsPropsConfig } from "@incarail/killa-common";
import { Field, Formik } from "formik";
import { addDays, format } from "date-fns";
import { useTranslation } from 'next-i18next';

import { useTicketsFormSchema } from "@/hooks/useTicketFormSchema";
import { Notification } from "../../base";

import { useRouter } from "next/router";
import { useBookerContext } from '@/context/BookerContext';

import styles from "./BookerEnhanced.module.scss";
import { BookerStationAutocomplete } from "./BookerStationAutoComplete";
import { BookerDatePicker } from "./BookerDatePicker";
import { BookerPassengers } from "./BookerPassengers";

export const BookerEnhanced = (props: BookerDefaultProps) => {
  const { bookerConfig } = props;
  const { tickets, travelPackages, availableJourneys } = bookerConfig;

  const { locale, push } = useRouter();

  const { t } = useTranslation('form');
  const ticketsFormSchema = useTicketsFormSchema();

  const [ticketsFormData, setTicketsFormData] = useState<BookerTicketsPropsConfig>({
    journey_types: 0,
    orientation: 0,
    route_id: 5,
    from: addDays(Date.now(), 1),
    to: addDays(Date.now(), 1),
    currency: 'USD',
    passengers: {
      adults: 2,
      children: 0,
      babies: 0,
    }
  });

  const { isActive, activateBooker, deactivateBooker } = useBookerContext();

  const bookerRef = useRef<HTMLDivElement>(null);

  const checkAndScrollToBooker = useCallback(() => {
    if (bookerRef.current) {
      const viewportHeight = window.innerHeight;
      const bookerObject = bookerRef.current.getBoundingClientRect();
      const desiredOffset = viewportHeight * 0.10;
      const scrollY = bookerObject.top + window.scrollY - desiredOffset;
      window.scrollTo({ top: scrollY, behavior: 'smooth' });
      activateBooker();
    }
  }, [activateBooker]);

  const defaultJourneys = [{
      label: t('roundTrip'),
      value: 0
    }, {
      label: t('oneWay'),
      value: 1
    },
  ];

  useEffect(() => {
    if (isActive) {
      checkAndScrollToBooker();
    }
  }, [isActive, checkAndScrollToBooker]);

  const availableRoutes = [
    { id: 5, origin: 'Cusco', destination: 'Machu Picchu', reverse: false, orientation: 1 },
    { id: 1, origin: 'Ollantaytambo', destination: 'Machu Picchu', reverse: false, orientation: 1 },
    { id: 6, origin: 'Machu Picchu', destination: 'Cusco', reverse: true, orientation: 2 },
    { id: 2, origin: 'Machu Picchu', destination: 'Ollantaytambo', reverse: true, orientation: 2 },
  ];  

  return (
    <div
      id="Booker"
      ref={bookerRef}
      className={[styles.Booker, isActive ? styles.BookerInteract : ''].join(' ')}
    >
      <div className={styles.BookerInner}>
        <div className={styles.BookerTabs}>
          <button
            type="button"
            className={['btn', styles.BookerTab, (tickets?.isDark ? styles.BookerTabDark : ''), styles.BookerTabActive].join(' ')}
          >
            <span>
              {t('trains')}
            </span>
          </button>
          <a
            target={travelPackages?.openNewWindow ? '_blank' : '_self'}
            rel={travelPackages?.openNewWindow ? 'noreferrer' : ''}
            className={['btn', styles.BookerTab, (travelPackages?.isDark ? styles.BookerTabDark : '')].join(' ')}
            href={locale === 'es' ? `https://incarail.com/${locale}/tours` : `https://incarail.com/${locale}/tours`}
          >
            <span>
              {t('travelPackages')}
            </span>
          </a>
        </div>
        <div className={[styles.BookerForm, styles.BookerFormTickets, (tickets?.isDark ? styles.BookerFormDark : '')].join(' ')}>
        <Formik
          initialValues={ticketsFormData}
          validationSchema={ticketsFormSchema}
          validateOnChange={true}
          validateOnMount={false}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            // Utiliza `values` aquí en lugar de `ticketsFormData`
            // const submitUrl = `${tickets?.searchUrl ?? 'https://zonasegura.incarail.com'}/itinerario/buscar/${tickets?.searchAbt ? `idAbt/${tickets.searchAbt}/`:''}${values.route_id ? `idRuta/${values.route_id}/`:''}idViajeSentido/${values.orientation}${(values.from && values.journey_types !== 2) ? `/fecViajeIda/${format(values.from, 'ddMMyyyy')}` : ''}${(values.to && values.journey_types === 0) ? `/fecViajeRegreso/${format(values.to, 'ddMMyyyy')}` : ''}${(values.journey_types === 2) ? `/fecViajeRegreso/${format(values.from, 'ddMMyyyy')}` : ''}/numCupoAdulto/${values.passengers.adults}/numCupoNino/${values.passengers.children}/numCupoInfante=${values.passengers.babies}?language=${locale}`;
            const submitUrl = `${tickets?.searchUrl ?? 'https://zonasegura.incarail.com'}/itinerario/buscar/${tickets?.searchAbt ? `idAbt/${tickets.searchAbt}/`:''}${values.route_id ? `idRutaFiltro/${values.route_id}/`:''}idViajeSentido/${values.journey_types !== 2 && values.orientation === 2 ? 2 : values.journey_types}${(values.from && values.journey_types !== 2 && values.orientation === 1) ? `/fecViajeIda/${format(values.from, 'ddMMyyyy')}` : `/fecViajeRegreso/${format(values.from, 'ddMMyyyy')}`}${(values.to && values.journey_types === 0) ? `/fecViajeRegreso/${format(values.to, 'ddMMyyyy')}` : ''}${(values.journey_types === 2) ? `/fecViajeRegreso/${format(values.from, 'ddMMyyyy')}` : ''}/numCupoAdulto/${values.passengers.adults}/numCupoNino/${values.passengers.children}/numCupoInfante=${values.passengers.babies}?language=${locale}`;

            if (tickets?.openNewWindow) {
              window.open(submitUrl, '_blank');
              window.location.href = locale === 'es' ? `${process.env.KILLA_URL}/${locale}/tours?redirectBooker=1` : `${process.env.KILLA_URL}/${locale}/tours?redirectBooker=1`;
            } else {
              push(submitUrl);
            }

            setSubmitting(false);
            deactivateBooker();
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            values, // Aquí están los valores actualizados de Formik
            validateField,
            isValid
          }) => {
            return (
              <form
                target="_blank"
                method="get"
                onSubmit={handleSubmit}
                className={[styles.BookerFormInner].join(' ')}
              >
                <div className={styles.BookerFormNotification}>
                  {errors.journey_types &&
                    <Notification type="danger" message={t('errors.journeyTypeRequired')} />}
                  {errors.from &&
                    <Notification type="danger" message={t('errors.fromDateRequired')} />}
                  {errors.to &&
                    <Notification type="danger" message={t('errors.toDateRequired')} />}
                </div>
                <div className={['form-group', styles.BookerFormGroup, styles.BookerFormGroupJourney].join(' ')}>
                  {(availableJourneys ?? defaultJourneys).map((journey: BookerJourneyTypesPropsConfig) => (
                    <div className="form-check form-check-inline" key={journey.label}>
                      <input
                        className="form-check-input"
                        checked={values.journey_types === journey.value}
                        type="radio"
                        name="journey_type"
                        id={`journey_type_${journey.value}`}
                        value={journey.value}
                        onChange={(e) =>
                          setFieldValue("journey_types", parseInt(e.target.value))
                        }
                      />
                      <label className="form-check-label" htmlFor={`journey_type_${journey.value}`}>{journey.label}</label>
                    </div>
                  ))}
                </div>
                <div className={[styles.BookerFormGroup, styles.BookerFormGroupLocations].join(' ')}>
                  <Field
                    name="route_id"
                    component={BookerStationAutocomplete}
                    routes={availableRoutes}
                    defaultRoute={5}
                    placeholderOrigin="Select origin"
                    placeholderDestination="Select destination"
                    className="form-control"
                    withReverse={values.journey_types !== 0}
                  />
                </div>
                <div className={[styles.BookerFormGroup, styles.BookerFormGroupDates].join(' ')}>
                  <Field
                    name="dateRange"
                    component={BookerDatePicker}
                    placeholderFrom={t('departure')}
                    placeholderTo={t('return')}
                    withRange={values.journey_types === 0}
                  />
                </div>
                <div className={[styles.BookerFormGroup, styles.BookerFormGroupPassengers].join(' ')}>
                  <Field
                    name="passengers"
                    component={BookerPassengers}
                  />
                </div>
                <div className={['form-group', styles.BookerFormGroup, styles.BookerFormGroupAction].join(' ')}>
                  <button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    className={['btn', styles.BookerFormSubmit].join(' ')}
                  >
                    {t('searchTickets')}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
        </div>
      </div>
      <div className={styles.BookerBack}></div>
    </div>
  );
};
