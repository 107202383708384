import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';

import styles from "./Counterdown.module.scss"

type CounterdownProps = {
  targetDate: Date;
};

export const Counterdown = ({ targetDate }: CounterdownProps) => {
  const [timeLeft, setTimeLeft] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const { t } = useTranslation('counterdown');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate.getTime() - now;
      if (distance < 0) {
        clearInterval(intervalId);
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimeLeft({
          days,
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [targetDate]);

  return (
    <div className={styles.Counterdown}>
      <div className={styles.CounterdownLabel}>
        {t('expiresIn')}
      </div>
      <div className={styles.CounterdownBoard}>
        <div className={styles.CounterdownDigit}>
          <div className={styles.CounterdownDigitBox}>
            {timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}
          </div>
          <div className={styles.CounterdownDigitText}>
            {t('days')}
          </div>
        </div>
        <div className={styles.CounterdownSep}>:</div>
        <div className={styles.CounterdownDigit}>
          <div className={styles.CounterdownDigitBox}>
            {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}
          </div>
          <div className={styles.CounterdownDigitText}>
            {t('hrs')}
          </div>
        </div>
        <div className={styles.CounterdownSep}>:</div>
        <div className={styles.CounterdownDigit}>
          <div className={styles.CounterdownDigitBox}>
            {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}
          </div>
          <div className={styles.CounterdownDigitText}>
            {t('min')}
          </div>
        </div>
        <div className={styles.CounterdownSep}>:</div>
        <div className={styles.CounterdownDigit}>
          <div className={styles.CounterdownDigitBox}>
            {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
          </div>
          <div className={styles.CounterdownDigitText}>
            {t('sec')}
          </div>
        </div>
      </div>
    </div>
  );
};