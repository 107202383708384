import React from "react";
import { Headline as HeadlineDefaultProps } from "@incarail/killa-common";
import styles from "./Headline.module.scss";
import { getImageBySize } from "@/utils";
import { Asset } from "contentful";

export const Headline = (props: HeadlineDefaultProps) => {
  const { sys, tagline, title, isTaglineFirst, backgroundImage, backgroundColor, stylesConfig } = props;

  const headlineStyle = stylesConfig?.headline;
  const currentStyle = headlineStyle?.style ?? "1";

  // Extraer las imágenes de fondo para mobile y desktop
  const mobileBackgroundImage = backgroundImage?.find((img: Asset) => img.fields.title === "mobile")?.fields.file.url ?? "";
  const desktopBackgroundImage = backgroundImage?.find((img: Asset) => img.fields.title === "desktop")?.fields.file.url ?? "";

  const renderTitle = (content: string, htmlTag: string, className: string) => {
    const Tag = htmlTag || "h2";
    return (
      content &&
      React.createElement(Tag, {
        className: className ?? styles.HeadlineTitle,
        dangerouslySetInnerHTML: { __html: content ?? "" },
      })
    );
  };

  const renderHeadlineContent = () => (
    <div
      className={[
        currentStyle === "3" ? styles.HeadlineStandard : styles.Headline,
        currentStyle === "2" ? styles.HeadlineFull : "",
        `Headline-${sys.id}`,
      ].join(" ")}
      style={{ backgroundColor }}
    >
      {isTaglineFirst ? (
        <>
          {tagline && renderTitle(tagline, headlineStyle?.title?.htmlTag || "h4", styles.HeadlineTagline)}
          {title && renderTitle(title, headlineStyle?.title?.htmlTag || "h2", styles.HeadlineTitle)}
        </>
      ) : (
        <>
          {title && renderTitle(title, headlineStyle?.title?.htmlTag || "h2", styles.HeadlineTitle)}
          {tagline && renderTitle(tagline, headlineStyle?.title?.htmlTag || "h4", styles.HeadlineTagline)}
        </>
      )}
    </div>
  );

  const mobileStyles = `
    ${headlineStyle?.image?.mobile?.height ? `height:${headlineStyle.image.mobile.height};` : ""}
    ${headlineStyle?.advanced?.mobile?.padding ? `padding:${headlineStyle.advanced.mobile.padding.top ?? 0} ${headlineStyle.advanced.mobile.padding.right ?? 0} ${headlineStyle.advanced.mobile.padding.bottom ?? 0} ${headlineStyle.advanced.mobile.padding.left ?? 0};` : ""}
    ${headlineStyle?.advanced?.mobile?.margin ? `margin:${headlineStyle.advanced.mobile.margin.top ?? 0} ${headlineStyle.advanced.mobile.margin.right ?? 0} ${headlineStyle.advanced.mobile.margin.bottom ?? 0} ${headlineStyle.advanced.mobile.margin.left ?? 0};` : ""}
    ${headlineStyle?.tagline?.mobile?.align ? `text-align:${headlineStyle.tagline.mobile.align};` : ""}
    ${headlineStyle?.tagline?.mobile?.color ? `color:${headlineStyle.tagline.mobile.color};` : ""}
    ${headlineStyle?.title?.mobile?.align ? `text-align:${headlineStyle.title.mobile.align};` : ""}
    ${headlineStyle?.title?.mobile?.color ? `color:${headlineStyle.title.mobile.color};` : ""}
    ${currentStyle === "2" && mobileBackgroundImage ? `background-image: url(${mobileBackgroundImage});` : ""}
    ${currentStyle === "2" ? "background-size: cover;" : ""}
  `.trim();

  const tabletStyles = `
    ${headlineStyle?.image?.tablet?.height ? `height:${headlineStyle.image.tablet.height};` : ""}
    ${headlineStyle?.advanced?.tablet?.padding ? `padding:${headlineStyle.advanced.tablet.padding.top ?? 0} ${headlineStyle.advanced.tablet.padding.right ?? 0} ${headlineStyle.advanced.tablet.padding.bottom ?? 0} ${headlineStyle.advanced.tablet.padding.left ?? 0};` : ""}
    ${headlineStyle?.advanced?.tablet?.margin ? `margin:${headlineStyle.advanced.tablet.margin.top ?? 0} ${headlineStyle.advanced.tablet.margin.right ?? 0} ${headlineStyle.advanced.tablet.margin.bottom ?? 0} ${headlineStyle.advanced.tablet.margin.left ?? 0};` : ""}
    ${headlineStyle?.tagline?.tablet?.align ? `text-align:${headlineStyle.tagline.tablet.align};` : ""}
    ${headlineStyle?.title?.tablet?.align ? `text-align:${headlineStyle.title.tablet.align};` : ""}
  `.trim();

  const desktopStyles = `
    ${headlineStyle?.image?.desktop?.height ? `height:${headlineStyle.image.desktop.height};` : ""}
    ${headlineStyle?.advanced?.desktop?.padding ? `padding:${headlineStyle.advanced.desktop.padding.top ?? 0} ${headlineStyle.advanced.desktop.padding.right ?? 0} ${headlineStyle.advanced.desktop.padding.bottom ?? 0} ${headlineStyle.advanced.desktop.padding.left ?? 0};` : ""}
    ${headlineStyle?.advanced?.desktop?.margin ? `margin:${headlineStyle.advanced.desktop.margin.top ?? 0} ${headlineStyle.advanced.desktop.margin.right ?? 0} ${headlineStyle.advanced.desktop.margin.bottom ?? 0} ${headlineStyle.advanced.desktop.margin.left ?? 0};` : ""}
    ${headlineStyle?.tagline?.desktop?.align ? `text-align:${headlineStyle.tagline.desktop.align};` : ""}
    ${headlineStyle?.title?.desktop?.align ? `text-align:${headlineStyle.title.desktop.align};` : ""}
    ${currentStyle === "2" && desktopBackgroundImage ? `background-image: url(${desktopBackgroundImage});` : ""}
    ${currentStyle === "2" ? "background-size: cover;" : ""}
  `.trim();

  const mobileMediaQuery = mobileStyles ? `.Headline-${sys.id} { ${mobileStyles} }` : "";
  const tabletMediaQuery = tabletStyles
    ? `@media (min-width: 768px) { .Headline-${sys.id} { ${tabletStyles} } }`
    : "";
  const desktopMediaQuery = desktopStyles
    ? `@media (min-width: 992px) { .Headline-${sys.id} { ${desktopStyles} } }`
    : "";

  const hasStyles = mobileMediaQuery || tabletMediaQuery || desktopMediaQuery;

  return (
    <>
      {renderHeadlineContent()}
      {hasStyles && (
        <style jsx>{`
          ${mobileMediaQuery}
          ${tabletMediaQuery}
          ${desktopMediaQuery}
        `}</style>
      )}
    </>
  );
};
