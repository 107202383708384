import { TravelPackageItem as TravelPackageItemDefaultProps } from '@incarail/killa-common';
import MediaImage from "@/components/base/MediaImage/MediaImage"
import styles from "./TravelPackagePot.module.scss"
import { PriceBox } from '@/components/base';

export const TravelPackagePot = ({ travelPackageUrl, travelPackageImages, travelPackageName, travelPackagePrices, travelPackageQuickView }: TravelPackageItemDefaultProps) => {

  
  
  return (
    <div className={[styles.TravelPackagePot, styles.TravelPackagePotAlt].join(' ')}>
      <div className={styles.TravelPackagePotPicture}>
        <div className={styles.TravelPackagePotImage}>
          <a href={`https://incarail.com${travelPackageUrl ?? ''}`}>
            <MediaImage
              loading="lazy"
              src={`https://incarail.com${travelPackageImages[0]?.url ?? ''}`}
              alt={travelPackageName}
            />
          </a>
        </div>
      </div>
      <div className={styles.TravelPackagePotContent}>
        <h4 className={styles.TravelPackagePotHeadline}>
          <a href={`https://incarail.com${travelPackageUrl ?? ''}`}>
            {travelPackageName}
          </a>
        </h4>
        <div className={styles.TravelPackagesQuickView} dangerouslySetInnerHTML={{ __html: travelPackageQuickView }} />
        <div className={styles.TravelPackagePotPrices}>
          <PriceBox
            isSmall
            fromAmount={Math.round(travelPackagePrices?.travelPackageSalePrice ?? 0)}
            fromCurrency="USD"
            fromLocalAmount={Math.round(travelPackagePrices?.travelPackageExchangeRate ?? 0)}
            fromLocalCurrency="PEN"
          />
        </div>
      </div>
    </div>
  )
}