

import { DEFAULT_LANGUAGE,
  TravelPackageCategoryGroup,
  TravelPackages as TravelPackagesDefaultProps,
  TravelPackageItem as TravelPackageItemDefaultProps,
  TravelPackagerFilter
} from '@incarail/killa-common';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useInView } from '@/hooks';
import { getLocation, getTravelPackageCategories, getTravelPackages } from '@/utils';

import { TravelPackageCarousel } from './TravelPackageCarousel/TravelPackagesCarousel';
import Alert from '@/components/base/Alert/Alert';
import { useTranslation } from 'next-i18next';

import styles from "./TravelPackages.module.scss";
import { TravelPackageCategoryBar } from './TravelPackageCategoryBar/TravelPackageCategoryBar';
import { TravelPackageGrid } from './TravelPackageGrid/TravelPackagesGrid';
import { TravelPackageSearch } from './TravelPackageSearch/TravelPackagesSearch';

type RenderType = 'slider' | 'grid' | 'search'; // Añade más tipos según sea necesario
type RenderFunction = () => JSX.Element;

export const TravelPackages = (props: TravelPackagesDefaultProps) => {
  const { travelPackagesConfig } = props;

  const [travelPackageCategoriesData, setTravelPackageCategoriesData] = useState<TravelPackageCategoryGroup[] | null>(null);
  const [travelPackageData, setTravelPackageData] = useState<TravelPackageItemDefaultProps[] | null>(null);
  const [travelPackageFilter, setTravelPackageFilter] = useState<TravelPackagerFilter>({
    categoryGroupId: travelPackagesConfig?.filter?.categoryGroupId ?? '1',
    defaultCategoryId: travelPackagesConfig?.filter?.defaultCategoryId ?? '29',
    filterGroupIds: travelPackagesConfig?.filter?.filterGroupIds ?? [],
    orderBy: travelPackagesConfig?.filter?.orderBy ?? 'relevance',
    packageListId: travelPackagesConfig?.filter?.packageListId ?? '7',
    people: travelPackagesConfig?.filter?.people ?? 2,
    from: travelPackagesConfig?.filter?.from ?? '',
    to: '',  
  });
  const { ref, isInView } = useInView();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const { locale } = useRouter();

  const filterCategoryGroup = travelPackageFilter.categoryGroupId ?? null;
  const filterList = travelPackageFilter.packageListId ?? null;

  const { t } = useTranslation('travelPackages');
  
  const fetchData = useCallback(async () => {
    const responseCategories = await getTravelPackageCategories(locale ?? DEFAULT_LANGUAGE, '');
    const response = await getTravelPackages(locale ?? DEFAULT_LANGUAGE, filterList, false);

    setTravelPackageCategoriesData(responseCategories);
    setTravelPackageData(!response?.errors ? response : null);

    setErrorLoading(!!response?.errors);
  }, [locale, filterList]);

  const renderGrid = () => (
    <>
      {travelPackagesConfig?.grid?.withCategoryBar && <TravelPackageCategoryBar
        travelGroups={travelPackageCategoriesData?.find((c: TravelPackageCategoryGroup) => filterCategoryGroup === c.groupId.toString()) ?? null}
        travelPackageFilter={travelPackageFilter}
        onSelectFilter={(travelFilters) => {
          setTravelPackageFilter(currentFilters => ({
            ...currentFilters,
            ...travelFilters
          }))
        }}
        isLoading={loading}
      />}
      <TravelPackageGrid
        travelPackageCategories={travelPackageCategoriesData}
        travelPackages={travelPackageData}
        travelPackageFilter={travelPackageFilter}
        isLoading={loading}
      />
    </>
  )

  const renderSlider = () => (
    <>
      <TravelPackageCategoryBar
        travelGroups={travelPackageCategoriesData?.find((c: TravelPackageCategoryGroup) => filterCategoryGroup === c.groupId.toString()) ?? null}
        travelPackageFilter={travelPackageFilter}
        onSelectFilter={(travelFilters) => {
          setTravelPackageFilter(currentFilters => ({
            ...currentFilters,
            ...travelFilters
          }))
        }}
        isLoading={loading}
      />
      <TravelPackageCarousel
        travelPackageCategories={travelPackageCategoriesData}
        travelPackages={travelPackageData}
        travelPackageFilter={travelPackageFilter}
        isLoading={loading}
      />
    </>
  )

  const renderSearch = () => (
    <TravelPackageSearch
      travelPackageCategories={travelPackageCategoriesData}
      travelPackages={travelPackageData}
      travelPackageFilter={travelPackageFilter}
      isLoading={loading}
    />
  )

  const renderFunctions: Record<RenderType, RenderFunction> = {
    slider: renderSlider,
    grid: renderGrid,
    search: renderSearch,
  };
  
  useEffect(() => {
    if (isInView) {
      fetchData();
    }
  }, [isInView, fetchData]);

  useEffect(() => {
    if(travelPackageFilter && travelPackageData && travelPackageCategoriesData) {
      setLoading(false);
    }
  }, [travelPackageFilter, travelPackageData, travelPackageCategoriesData])

  return (
    <div className={[styles.TravelPackages].join(' ')} ref={ref}>
      
      <div className={styles.TravelPackagesInner}>
        {(!errorLoading) && (
          renderFunctions[travelPackagesConfig?.type ?? 'slider']()
          ?? <div>Contenido predeterminado o mensaje de error</div>
        )}
        {(errorLoading) && (
          <div className={styles.TravelPackageEmpty}>
            <Alert
              message={t('errorAlert')}
              actionLabel={t('updateAgain')}
              onClick={() => {
                setErrorLoading(!errorLoading);
                setLoading(!loading);
                fetchData();
              }}
            />
          </div>
        )}

      </div>
    </div>
  );
};