// Booker.tsx
import { Booker as BookerDefaultProps, BookerStylesConfig } from "@incarail/killa-common";
import { BookerClassic } from "./BookerClassic";
import { BookerEnhanced } from "./BookerEnhanced";

interface BookerProps extends BookerDefaultProps {
  version: 'classic' | 'enhanced';
}

export const Booker = (props: BookerProps) => {
  const { stylesConfig, ...rest } = props;
  const { booker } = stylesConfig as BookerStylesConfig;

  const currentVersion = booker?.style ?? 'classic';

  return (
    <>
      {currentVersion === 'classic' && <BookerClassic {...rest} />}
      {currentVersion === 'enhanced' && <BookerEnhanced {...rest} />}
    </>
  );
};
