import * as Yup from 'yup';

export function createTicketsFormSchema(t: (key: string) => string) {
  return Yup.object().shape({
    journey_types: Yup.number().required(t('errors.journeyTypeRequired')),
    route_id: Yup.number().required(t('errors.routeRequired')),
    from: Yup.date().required(t('errors.fromDateRequired')),
    to: Yup.date().nullable(),
    currency: Yup.string().required(t('errors.currencyRequired')),
    passengers: Yup.object()
      .shape({
        adults: Yup.number().min(1, t('errors.adultsMinOne')).required(t('errors.adultsRequired')),
        children: Yup.number(),
        babies: Yup.number(),
      })
  });
}