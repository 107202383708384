import React, { useState, useRef, ReactNode, useEffect } from 'react';
import { Options, Splide, SplideProps } from '@splidejs/react-splide';


import '@splidejs/splide/dist/css/splide.min.css';

export const SplideSlider: React.FC<SplideProps> = ({
  className,
  options,
  children,
  extensions,
  ...props
}) => {

  const splideRef = useRef<Splide | null>(null);

  const [ splideOptions ] = useState<Options>(options || {});

  return (
    <div className={className}>
      <Splide ref={splideRef} options={splideOptions} extensions={extensions} {...props}>
        {children}
      </Splide>
      <style jsx global>
        {`
          .main-slider {
            .splide__list {
              align-items: center;
            }
          }
      `}
      </style>
    </div>
  );

}