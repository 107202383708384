import * as Yup from 'yup';

export function createLeadFormSchema(t: (key: string) => string) {
  return Yup.object().shape({
    formId: Yup.string().required(t('errors.formIdRequired')),
    firstName: Yup.string().required(t('errors.firstNameRequired')),
    lastName: Yup.string().required(t('errors.lastNameRequired')),
    email: Yup.string().email(t('errors.emailInvalid')).required(t('errors.emailRequired')),
    phone: Yup.string().matches(/^\+[1-9]\d{6,14}$/, t('errors.phoneInvalid')).required(t('errors.phoneRequired')),
  });
}