import { GetServerSidePropsContext } from 'next';
import buildClient from '../../pages/api/build-client';

export async function sendLead(
  location: string,
  fullName: string,
  phone: string,
  email: string,
  observation: string,
  context?: GetServerSidePropsContext) {

  // Connect to API and retrieve content
  const client = buildClient(context ?? undefined);

  const { data } = await client.post(
    `/api/sir/register-lead`, {
      location, fullName, email, phone, observation,
    }
  ).then((response) => {
    return response;
  }).catch((err) => {
    return err.response;
  });

  return data || null;
}

