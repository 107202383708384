import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'next-i18next';
import { sendEmail } from '@/utils';
import { useRouter } from 'next/router';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
  recaptcha: Yup.string().required('Please verify that you are not a robot'),
});

const ContactForm = () => {

  const { t } = useTranslation('form');

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const { locale } = useRouter();

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setSubmitting(true);
    const contactResponse = await sendEmail(values.name, values.email, values.subject, values.message, locale);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ name: '', email: '', subject: '', message: '', recaptcha: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <div className="mb-3 row">
            <label htmlFor="name" className="col-sm-2 col-form-label">{t('name')}</label>
            <div className="col-sm-10">
              <Field type="text" id="name" name="name" placeholder="Name" className="form-control" />
              <ErrorMessage name="name" component="div" className="form-text" />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="email" className="col-sm-2 col-form-label">{t('email')}</label>
            <div className="col-sm-10">
              <Field type="email" id="email" name="email" placeholder="Email" className="form-control" />
              <ErrorMessage name="email" component="div" className="form-text" />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="subject" className="col-sm-2 col-form-label">{t('subject')}</label>
            <div className="col-sm-10">
              <Field as="select" name="subject" className="form-select" >
                <option value="">{t('selectChoice')}</option>
                <option value="4">{t('questions')}</option>
                <option value="5">{t('exchangesRefunds')}</option>
                <option value="6">{t('claims')}</option>
                <option value="7">{t('others')}</option>
              </Field>              
              <ErrorMessage name="subject" component="div" className="form-text" />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="subject" className="col-sm-2 col-form-label">{t('message')}</label>
            <div className="col-sm-10">
              <Field as="textarea" rows="9" name="message" placeholder="Message" className="form-control" />
              <ErrorMessage name="message" component="div" className="form-text" />
            </div>
          </div>

          <div className="mb-3 row">
            <ReCAPTCHA
              sitekey="6Lebu2IUAAAAAAWQjPF9rVuSaXpbFhpVIJ56AJH0"
              onChange={(value) => {
                setFieldValue("recaptcha", value);
                setRecaptchaValue(value);
              }}
            />
            {recaptchaValue === null && <div className="form-text">Please verify that you are not a robot</div>}
          </div>

          <button className="btn btn-danger" type="submit" disabled={isSubmitting}>
          {t('submit')}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
