import { useState, useEffect } from "react";
import { useTranslation } from 'next-i18next';

import styles from "./Select.module.scss"
import CloseButton from '@/assets/icons/close.svg';
import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';
import IconMinus from '@/assets/icons/minus.svg';
import IconPlus from '@/assets/icons/plus.svg';
import { isMobile } from "@/utils/shared/app/isMobile";
import { BookerTicketsPassengersPropsConfig } from "@incarail/killa-common";

import { useBookerContext } from '@/context/BookerContext';

interface SelectDefaultProps {
  className?: string,
  label?: string,
  options?: BookerTicketsPassengersPropsConfig
  onSelect: (option: BookerTicketsPassengersPropsConfig) => void,
}

export const SelectPassengers = (props: SelectDefaultProps) => {

  const { deactivateBooker } = useBookerContext();
  const { t } = useTranslation('form');

  const { label, className, options, onSelect } = props;
  const [openSelect, setOpenSelect] = useState(false);
  const [selected, setSelected] = useState<BookerTicketsPassengersPropsConfig>({
    adults: 2,
    children: 0,
    babies: 0,
  });

  let closeTimer: NodeJS.Timeout;

  const handleClose = () => {
    if (isMobile) return;  // Don't cancel the auto-close logic on mobile devices
    if (closeTimer) clearTimeout(closeTimer);
    closeTimer = setTimeout(() => {
      setOpenSelect(false);
      deactivateBooker();
    }, 1000);  // Adjust delay as per your needs
  }

  const handleMouseEnter = () => {
    if (isMobile) return;  // Don't cancel the auto-close logic on mobile devices
    if (closeTimer) clearTimeout(closeTimer);  // Cancel the timer when the mouse re-enters the list
  }

  const updateCounter = (label: string, value: number) => {
    setSelected(selected => ({
      ...selected,
      [label]: value,
    }))
  }

  useEffect(() => {
    onSelect(selected);
  }, [selected])

  return (
    <div
      className={[className ?? '', styles.Select, styles.SelectPassengers, openSelect ? styles.SelectOpen : ''].join(' ').trim()}
      onMouseLeave={handleClose}  // Handle the onMouseLeave event to close the list
      onMouseEnter={handleMouseEnter}  // Handle the onMouseEnter event to cancel the close timer
    >
      <button
        type="button"
        aria-label="Open Passengers"
        className={['btn', styles.SelectButton].join(' ')}
        onClick={() => {
          setOpenSelect(!openSelect);
        }}
      >
        {selected.adults + selected.children}
        <IconChevronDown />

      </button>
      <div className={styles.SelectBack}>
        <div
          className={styles.SelectContent}
        >
          <div className={styles.SelectContentHeader}>
            <div className={styles.SelectContentHeaderLabel}>
              {label ?? t('chooseOption')}
            </div>
            <button
              type="button"
              aria-label="Close Passengers"
              className={['btn', styles.SelectContentClose].join(' ')}
              onClick={() => {
                setOpenSelect(!openSelect);
              }}
            >
              <CloseButton />
            </button>
          </div>
          <div className={styles.SelectPassengerList}>
            <div className={styles.SelectPassengerListContent}>
              {Object.entries(selected).map(([k, v]) => {
                const key = k as 'adults' | 'children' | 'babies';
                return (
                <div className={styles.SelectPassengerItem} key={k}>
                  <div className={styles.SelectPassengerItemLabel}>{t(k)} <span>{t(`${k}Hint`)}</span></div>
                  <div className={styles.SelectPassengerItemCounter}>
                    <button
                      type="button"
                      aria-label={`increase ${k}`}
                      className={['btn', styles.SelectPassengerItemCounterButton].join(' ')}
                      disabled={selected.adults + selected.children <= 0}
                      onClick={() => {
                        if(key === 'adults' && v === selected.babies) {
                          updateCounter('babies', (parseInt(v) - 1) < 0 ? 0 : parseInt(v) - 1)
                        }
                        if(key === 'adults') {
                          updateCounter(k, (parseInt(v) - 1) < 1 ? 1 : parseInt(v) - 1)
                        } else {
                          updateCounter(k, (parseInt(v) - 1) < 0 ? 0 : parseInt(v) - 1)
                        }
                      }}
                    >
                      <IconMinus />
                    </button>
                    <div className={styles.SelectPassengerItemCounterNumber}>{selected[key]}</div>
                    <button
                      type="button"
                      aria-label={`decrease ${k}`}
                      className={['btn', styles.SelectPassengerItemCounterButton].join(' ')}
                      disabled={selected.adults + selected.children >= 10}
                      onClick={() => {
                        if(key === 'babies') {
                          updateCounter(k, (parseInt(v) + 1) > selected.adults ? selected.adults : parseInt(v) + 1)
                        } else {
                          updateCounter(k, (parseInt(v) + 1) > 10 ? 10 : parseInt(v) + 1)
                        }
                      }}
                      >
                      <IconPlus />
                    </button>
                  </div>
                  {(k === 'babies' && v > 0) &&
                  <div className={styles.SelectPassengerItemFoot}>
                    {t('babiesFoot')}
                  </div>}
                </div>)
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};