import React from 'react';
import { Entry } from 'contentful';
import ContentItemRenderer from './ContentItemRender';
import { ContentfulComponents as ComponentsMap } from './content';

interface ContentItem {
  sys: {
    id: string;
    contentType: {
      sys: {
        id: keyof ComponentsMap;
      };
    };
  };
  fields: {
    [key: string]: any;
  };
}

interface DynamicRendererProps {
  contentArray: Entry<ContentItem>[],
  skipSection?: boolean,
}

const DynamicRenderer: React.FC<DynamicRendererProps> = ({ contentArray, skipSection = false }) => {
  return (
    <React.Fragment>
      {(contentArray || []).map((item, idx) => (
        <ContentItemRenderer key={`${item.sys.id}_${idx}`} item={item} skipSection={skipSection} />
      ))}
    </React.Fragment>
  );
};

export default DynamicRenderer;
