import { DeviceType, Spacer as SpacerDefaultProps } from "@incarail/killa-common";
import styles from "./Spacer.module.scss"

export const Spacer = (props: SpacerDefaultProps) => {
  const { withHorizontalLine, stylesConfig } = props;
  const spacerStyle = stylesConfig?.spacer;

  const contentStyles = (device: DeviceType) => {
    const style = spacerStyle?.style?.[device];
    const line = spacerStyle?.line?.[device];
    return `
      .${styles.Spacer} {
        align-items: ${line?.align ?? 'center'};
        ${style?.height ? `height: ${style.height}` : ''};
      }
      
      .${styles.Spacer} hr {
        ${line?.color ? `border-color: ${line.color}` : ''};
        ${line?.height ? `border-width: ${line.height}` : ''};
        ${line?.spacing ? `margin: ${line.spacing} 0` : ''};
        ${line?.width ? `width: ${line.width}` : ''};
      }
    `;
  };

  return (
    <>
      <div className={[styles.Spacer].join(' ')}>
        {withHorizontalLine && <hr />}
      </div>
      {spacerStyle &&
      <style jsx>
      {`
        ${contentStyles('mobile')}
        
        @media (min-width: 768px) {
          ${contentStyles('tablet')}
        }

        @media (min-width: 992px) {
          ${contentStyles('desktop')}
        }

      `}
      </style>}
    </>
  );
};