
import { useTranslation } from 'next-i18next';
import styles from "./PriceBox.module.scss"

type PriceBoxProps = {
  isSmall?: boolean,
  fromAmount: number,
  fromCurrency: string,
  fromLocalAmount?: number,
  fromLocalCurrency?: string,
};

export const PriceBox = ({ isSmall, fromAmount, fromCurrency, fromLocalAmount, fromLocalCurrency }: PriceBoxProps) => {

  const { t } = useTranslation('prices');

  return (
    <div className={[styles.PriceBox, styles.PriceBoxInline, isSmall ? styles.PriceBoxSmall : ''].join(' ')}>
      <div className={styles.PriceBoxFromTag}>{t('from')}</div>
      <div className={styles.PriceBoxFrom}>
        <span className={styles.PriceBoxFromCurrency}>{fromCurrency}</span>
        <span className={styles.PriceBoxFromAmount}>{fromAmount}</span>
        <span className={styles.PriceBoxFromText}>{t('perPerson')}</span>
        {fromLocalAmount && 
        <span className={styles.PriceBoxFromLocal}>
          <span className={styles.PriceBoxFromLocalCurrency}>{fromLocalCurrency}</span>
          <span className={styles.PriceBoxFromLocalAmount}>{fromLocalAmount}</span>
        </span>}
      </div>
    </div>
  );
};