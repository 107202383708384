import { DynamicForm as DynamicFormProps } from "@incarail/killa-common";
import { FormLead } from "./FormLead";

import { useState } from "react";

import styles from "./Form.module.scss"

export const DynamicForm = (props: DynamicFormProps) => {

  const { id, headline, tagline, submitText, submitPushUrl, termsAndConditionsLink, cookiesPolicyLink, privacyPolicyLink, formType, closeAfter } = props;

  return (
    <div className={[styles.FormWrapper].join(' ')}>
      {formType === 'lead' && 
        <FormLead
          id={id}
          headline={headline}
          tagline={tagline}
          submitText={submitText}
          submitPushUrl={submitPushUrl}
          termsAndConditionsLink={termsAndConditionsLink}
          cookiesPolicyLink={cookiesPolicyLink}
          privacyPolicyLink={privacyPolicyLink}
          closeAfter={closeAfter}
        />
      }
      {!formType && <div>
        No form
        </div>
      }
    </div>
  );
};