import React, { useEffect, useState } from 'react';
import { FieldProps, getIn } from 'formik';

import IconOrientation from '@/assets/icons/orientation.svg';
import IconOneWay from '@/assets/icons/one-way.svg';

import styles from "./BookerStationAutocomplete.module.scss";

interface Route {
  id: number;
  origin: string;
  destination: string;
  reverse: boolean;
  orientation: number;
}

interface BookerStationAutocompleteProps extends FieldProps {
  className?: string;
  routes: Route[];
  placeholderOrigin: string;
  placeholderDestination: string;
  withReverse: boolean;
  defaultRoute?: Route | number;
}

export const BookerStationAutocomplete: React.FC<BookerStationAutocompleteProps> = ({
  className,
  field,
  form,
  routes,
  placeholderOrigin,
  placeholderDestination,
  withReverse,
  defaultRoute,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState<Route | null>(() => {
    if (typeof defaultRoute === 'number') {
      return routes.find(route => route.id === defaultRoute) || null;
    }
    if (typeof defaultRoute === 'object' && defaultRoute !== null) {
      return defaultRoute as Route;
    }
    return routes.find(route => route.id === field.value) || null;
  });
    
  const [clickingSuggestion, setClickingSuggestion] = useState(false);

  const onClick = (route: Route) => {
    setShowSuggestions(false);
    setSelectedRoute(route);
    form.setFieldValue(field.name, route.id);
    setClickingSuggestion(false);
  };

  const onBlur = () => {
    if (clickingSuggestion) return;
    setShowSuggestions(false);
  };

  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const hasError = touch && error;

  useEffect(() => {
    if (!withReverse && selectedRoute?.reverse) {
      if (typeof defaultRoute === 'number') {
        setSelectedRoute(routes.find(route => route.id === defaultRoute) || null);
      } else if (typeof defaultRoute === 'object' && defaultRoute !== null) {
        setSelectedRoute(defaultRoute);
      } else {
        setSelectedRoute(null);
      }
    }
  }, [withReverse]);

  useEffect(() => {
    if (selectedRoute) {
      form.setFieldValue(field.name, selectedRoute.id);
      form.setFieldValue('orientation', selectedRoute.orientation);
  }
  }, [selectedRoute]);

  return (
    <>
      <div className="input-group">
        <input
          type="text"
          value={selectedRoute ? selectedRoute.origin : ''}
          onClick={() => setShowSuggestions(!showSuggestions)}
          onBlur={onBlur}
          className={['form-control', className, hasError ? 'is-invalid' : ''].join(' ')}
          placeholder={placeholderOrigin}
          autoComplete="off"
          readOnly
        />
        <span className={['input-group-text'].join(' ').trim()} onClick={() => {
          if(!withReverse || !selectedRoute) return false;
        
          const oppositeRoute = routes.find(
            (route: Route) =>
              route.origin === selectedRoute.destination &&
              route.destination === selectedRoute.origin
          );
      
          setSelectedRoute(oppositeRoute ?? null);
        }}>
          {!withReverse ? 
            <IconOrientation /> : 
            <IconOneWay />
          }
        </span>
        <input
          type="text"
          value={selectedRoute ? selectedRoute.destination : ''}
          onClick={() => setShowSuggestions(!showSuggestions)}
          onBlur={onBlur}
          className={['form-control', className, hasError ? 'is-invalid' : ''].join(' ')}
          placeholder={placeholderDestination}
          autoComplete="off"
          readOnly
        />
      </div>
      {hasError && <div className="invalid-feedback">{error}</div>}
      {showSuggestions && (
        <div className={styles.SuggestionListWrapper}>
          <ul className={styles.SuggestionsList}>
            {routes.filter((route: Route) => withReverse || route.reverse === false).map((route: Route, index) => (
              <li
                key={route.id}
                onMouseDown={() => setClickingSuggestion(true)}
                onClick={() => onClick(route)}
                className={[styles.Suggestion].join(' ')}
              >
                {route.origin} - {route.destination}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
