import { useRouter } from "next/router";
import { Promo, Promotions as PromotionsDefaultProps } from "@incarail/killa-common";
import { SplideSlider } from "@/components/base";
import { PromoCard } from "./PromoCard";

import styles from "./Promotions.module.scss";
import { useDeviceType } from "@/hooks";
import { SplideSlide } from "@splidejs/react-splide";

export const Promotions = ({ promos, stylesConfig }: PromotionsDefaultProps) => {
  const { pathname } = useRouter();
  const deviceType = useDeviceType();

  const promoLayout = stylesConfig?.promo?.[deviceType]?.layout || 'One';
  const promoType = stylesConfig?.promo?.[deviceType]?.type || 'grid';

  const renderPromo = () =>
    promos.map((promo: Promo, idx: number) => (
      <SplideSlide key={promo.sys.id}>
        <PromoCard promo={promo} index={idx} pathname={pathname} />
      </SplideSlide>
    )
  );

  return (
    <div className={styles.Promotions}>
      {promoType === 'grid' && (
        <div className={[styles.PromotionsGrid, styles[promoLayout]].join(' ')}>
          {promos.map((promo, index) => (
            <PromoCard key={promo.sys.id} promo={promo} index={index} pathname={pathname} />
          ))}
        </div>
      )}
      {(promos && promoType === 'carousel') && (
        <SplideSlider
          options={{
            arrows: false,
            autoplay: false,
            breakpoints: {
              1199: {
                perPage: 2
              },
              767: {
                perPage: 1
              }
            },
            classes: {
              arrows: 'splide__arrows ir-arrows',
              arrow: 'splide__arrow ir-arrow',
              prev: 'splide__arrow--prev ir-prev',
              next: 'splide__arrow--next ir-next',
              pagination: 'splide__pagination ir-pagination',
              page: 'splide__pagination__page ir-dot',
            },
            drag: true,
            gap: '1em',
            pagination: true,
            padding: { left: 0, right: 30 }
          }}
          >
          {renderPromo()}
        </SplideSlider>
      )}
    </div>
  );
};
