import { NavigationItem as NavigationItemDefaultProps } from "@incarail/killa-common";
import { ReactNode } from 'react';
import { BaseFooterNavigationItemLink } from './BaseFooterNavigationItemLink';

export const BaseFooterNavigationItemSubItem: React.FC<NavigationItemDefaultProps> = (ni) => {

  const renderSubNavItem = (nav: NavigationItemDefaultProps[], isFirstLevel: boolean = false) => (<>
    {nav.reduce((nl: ReactNode[], ni: NavigationItemDefaultProps) => {
      nl.push(<BaseFooterNavigationItemLink key={ni.sys.id} {...ni} />);
      return nl;
    }, [])}
  </>)

  return (
    <li key={ni.sys.id}>
      <BaseFooterNavigationItemLink {...ni} />
      {ni?.fields?.children?.length > 0 && (<>
        {renderSubNavItem(ni.fields.children)}
      </>)}
    </li>
  )

}