import React, { useState, useEffect, useRef } from 'react';
import { FieldProps, getIn } from 'formik';
import { useTranslation } from 'next-i18next';
import styles from './BookerPassengers.module.scss';
import IconArrowDown from '@/assets/icons/arrows/arrow-down.svg';
import IconAdults from '@/assets/icons/adults.svg';
import IconChild from '@/assets/icons/child.svg';
import IconBabies from '@/assets/icons/baby.svg';
import IconPassengers from '@/assets/icons/pax.svg';
import IconMinus from '@/assets/icons/minus.svg';
import IconPlus from '@/assets/icons/plus.svg';
import { BookerTicketsPassengersPropsConfig } from '@incarail/killa-common';
import { useBookerContext } from '@/context/BookerContext';

interface BookerPassengersProps extends FieldProps {
  className?: string;
  label?: string;
  options?: BookerTicketsPassengersPropsConfig;
}

export const BookerPassengers: React.FC<BookerPassengersProps> = ({
  field,
  form,
  className,
  label,
  options,
}) => {
  const { deactivateBooker } = useBookerContext();
  const { t } = useTranslation('form');
  const [showPassengers, setShowPassengers] = useState(false);
  const passengerRef = useRef<HTMLDivElement | null>(null);
  const [selected, setSelected] = useState<BookerTicketsPassengersPropsConfig>({
    adults: 2,
    children: 0,
    babies: 0,
  });

  useEffect(() => {
    setSelected(field.value || selected);
  }, [field.value]);

  const updateCounter = (label: string, value: number) => {
    const newSelected = {
      ...selected,
      [label]: value,
    };
    setSelected(newSelected);
    form.setFieldValue(field.name, newSelected);
  };

  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const hasError = touch && error;

  return (
    <div className={styles.BookerPassengers}>
      <div
        className={`input-group ${className ?? ''} ${hasError ? 'is-invalid' : ''}`}
        onClick={() => setShowPassengers(!showPassengers)}
      >      
        <span className="input-group-text" id="return_calendar">
          <IconPassengers />
        </span>
        <input
          type="text"
          value={`${selected.adults + selected.children}`}
          placeholder={label ?? t('chooseOption')}
          className={`form-control ${hasError ? 'is-invalid' : ''}`}
          readOnly
        />
        <span className="input-group-text">
          <IconArrowDown />
        </span>
        {hasError && <div className="invalid-feedback">{error}</div>}
      </div>
      {showPassengers && (
        <div className={styles.BookerContent} ref={passengerRef}>
          <div className={styles.BookerPassengerList}>
            <div className={styles.BookerPassengerListContent}>
              {Object.entries(selected).map(([k, v]) => {
                const key = k as 'adults' | 'children' | 'babies';
                return (
                  <div className={styles.BookerPassengerItem} key={k}>
                    <div className={styles.BookerPassengerItemIcon}>
                      {k === 'adults' && <IconAdults />}
                      {k === 'children' && <IconChild />}
                      {k === 'babies' && <IconBabies />}
                    </div>
                    <div className={styles.BookerPassengerItemLabel}>
                      {t(k)} <span>{t(`${k}Hint`)}</span>
                    </div>
                    <div className={styles.BookerPassengerItemCounter}>
                      <button
                        type="button"
                        aria-label={`decrease ${k}`}
                        className={`btn ${styles.BookerPassengerItemCounterButton}`}
                        disabled={selected.adults + selected.children <= 0}
                        onClick={() => {
                          if (key === 'adults' && v === selected.babies) {
                            updateCounter('babies', (parseInt(v) - 1) < 0 ? 0 : parseInt(v) - 1);
                          }
                          if (key === 'adults') {
                            updateCounter(k, (parseInt(v) - 1) < 1 ? 1 : parseInt(v) - 1);
                          } else {
                            updateCounter(k, (parseInt(v) - 1) < 0 ? 0 : parseInt(v) - 1);
                          }
                        }}
                      >
                        <IconMinus />
                      </button>
                      <div className={styles.BookerPassengerItemCounterNumber}>
                        {selected[key]}
                      </div>
                      <button
                        type="button"
                        aria-label={`increase ${k}`}
                        className={`btn ${styles.BookerPassengerItemCounterButton}`}
                        disabled={selected.adults + selected.children >= 10}
                        onClick={() => {
                          if (key === 'babies') {
                            updateCounter(k, (parseInt(v) + 1) > selected.adults ? selected.adults : parseInt(v) + 1);
                          } else {
                            updateCounter(k, (parseInt(v) + 1) > 10 ? 10 : parseInt(v) + 1);
                          }
                        }}
                      >
                        <IconPlus />
                      </button>
                    </div>
                    {k === 'babies' && v > 0 && (
                      <div className={styles.BookerPassengerItemFoot}>
                        {t('babiesFoot')}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.BookerOptions}>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
              setShowPassengers(false);
            }}>
              {t('close')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
