import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Hero as HeroDefaultProps, HeroSlide, HTMLContent, ImageBox } from "@incarail/killa-common";
import { SplideSlide } from "@splidejs/react-splide";
import { HeroSlide as HeroSlideComponent } from "./HeroSlide";
import ContentItemRenderer from "@/components/ContentItemRender";
import { trackSelectPromotion, trackViewPromotion } from "@/utils";
import { useRouter } from "next/router";
import { useInView } from "@/hooks";
import { ViewPromotionData, SelectPromotionData } from "@/interfaces";
import styles from "./Hero.module.scss";
import { Asset } from "contentful";
import { SplideSlider } from "@/components/base/SplideSlider";

interface HeroProps extends HeroDefaultProps {
  children: ReactNode;
}

function isHeroSlide(slide: HeroSlide | ImageBox | HTMLContent): slide is HeroSlide {
  return slide.sys.contentType.sys.id === 'heroSlide';
}

export const Hero = (props: HeroProps) => {
  const { locale } = useRouter();
  const { slides, children, stylesConfig, sys } = props;
  const { ref, isInView } = useInView(0.5);
  const [viewedSlides, setViewedSlides] = useState<string[]>([]);

  const withCaption = stylesConfig?.isCoverflow ?? false;
  const isInViewRef = useRef(isInView);
  const viewedSlidesRef = useRef<string[]>([]);

  const trackEventForSlide = (slide: HeroSlide, index: number, eventType: "ir.view_promotion" | "ir.select_promotion", link?: string) => {
    const imageIdList: string[] = slide.fields.images.map((i: Asset) => i.sys.id);
    if (eventType === "ir.view_promotion") {
      let eventData: ViewPromotionData = {
        promotionId: slide.sys.id,
        promotionOrder: index + 1,
        promotionName: slide.fields.name ?? '',
        promotionImagesId: imageIdList.join(' :: '),
        promotionLink1: slide.fields.leftLink,
        promotionLink2: slide.fields.rightLink ?? '',
        promotionType: sys?.contentType?.sys?.id,
        promotionLang: locale ?? '',
      };
      trackViewPromotion(eventData);
    } else {
      let eventData: SelectPromotionData = {
        promotionId: slide.sys.id,
        promotionOrder: index + 1,
        promotionName: slide.fields.name ?? '',
        promotionLinkSelected: link ?? '',
        promotionType: sys?.contentType?.sys?.id,
        promotionLang: locale ?? '',
      };
      trackSelectPromotion(eventData);
    }
  };

  const renderSlide = () =>
    slides.map((slide, idx) => {
      if (isHeroSlide(slide)) {
        return (
          <SplideSlide key={slide.sys.id}>
            <HeroSlideComponent
              slide={slide}
              withCaption={withCaption}
              idx={idx}
              onSelectPromotion={(link) => trackEventForSlide(slide, idx, "ir.select_promotion", link)}
            />
          </SplideSlide>
        );
      }

      return (
        <SplideSlide key={slide.sys.id}>
          <ContentItemRenderer key={slide.sys.id} item={slide} skipSection={true} />
        </SplideSlide>
      );
    });

  const handleSlideVisibility = (index: number) => {
    if (!isInViewRef.current) return;
    trackSlide(index);
  };

  const trackSlide = (index: number) => {
    const currentSlide = slides[index];
    const slideId = currentSlide.sys.id;

    if (!viewedSlidesRef.current.includes(slideId)) {
      if (isHeroSlide(currentSlide) && currentSlide.fields) {
        trackEventForSlide(currentSlide, index, "ir.view_promotion");
        setViewedSlides((prevViewedSlides) => [...prevViewedSlides, slideId]);
      }
    }
  };

  useEffect(() => {
    isInViewRef.current = isInView;
  }, [isInView]);

  useEffect(() => {
    viewedSlidesRef.current = viewedSlides;
  }, [viewedSlides]);

  return (
    <div key={sys.id} ref={ref}>
      <SplideSlider
        onReady={(splide) => trackSlide(splide.index)}
        onMoved={(splide, index) => handleSlideVisibility(index)}
        className={[styles.Hero, stylesConfig?.isCoverflow ? styles.HeroCoverflow : ''].join(' ')}
        options={stylesConfig?.hero}
      >
        {slides ? renderSlide() : children}
      </SplideSlider>
    </div>
  );
};
