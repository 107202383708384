import { Form as FormDefaultProps } from "@incarail/killa-common";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLeadFormSchema } from "@/hooks/useLeadFormSchema";
import { useTranslation } from 'next-i18next';

import Loader from "@/components/base/Loader/Loader";
import { useState } from "react";

import Link from "next/link";

import FormPhoneInput from "./FormPhoneInput/FormPhoneInput";
import { sendLead } from "@/utils/api-functions/sendLead";

import IconCheck from '@/assets/icons/check.svg';

import styles from "./Form.module.scss"
import { trackEvent } from "@/utils/shared/tracking/getTracking";

type FormLeadValues = {
  formId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  observation: string,
};

export const FormLead = (props: FormDefaultProps) => {

  const { id, headline, tagline, submitText, submitPushUrl, termsAndConditionsLink, closeAfter } = props;
  const { t } = useTranslation('form');
  const leadFormSchema = useLeadFormSchema();

  const [formSent, setFormSent] = useState<boolean>(false);
  const [seconds, setSeconds] = useState(closeAfter ?? 5);

  const initialValues: FormLeadValues = {
    formId: id,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    observation: ""
  };

  return (!formSent ? (
    <Formik
      initialValues={initialValues}
      validationSchema={leadFormSchema}
      validateOnChange={true}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async(values: FormLeadValues) => {
        const responseLead = await sendLead(values.formId, `${values.firstName} ${values.lastName}`, values.email, values.phone, values.observation);
        if(responseLead.success) {
          setFormSent(!formSent);

          trackEvent('generate_lead', {
            currency: 'USD',
            value: 380
          });

          if(submitPushUrl) {
            window.location.href = submitPushUrl
          }

        }
      }}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
        setFieldValue,
        values,
        validateField,
      }) => {
        return (
          <Form
            id={id ?? 'form_lead'}
            className={styles.Form}
            method="post"
            onSubmit={handleSubmit}
          >
            <div className={styles.FormHeader}>
              {headline && 
              <h4 className={styles.FormHeadline}>{headline}</h4>}
              {tagline && 
              <div  className={styles.FormTagline}>
                <p>{tagline}</p>
              </div>}
            </div>
            <div className={styles.FormBody}>

              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="firstName">{t('firstName')}*</label>
                    <Field id="firstName" name="firstName" placeholder={`${t('exampleAbrr')} ${t('firstName')}`} className="form-control" />
                    <ErrorMessage name="firstName" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="lastName">{t('lastName')}*</label>
                    <Field id="lastName" name="lastName" placeholder={`${t('exampleAbrr')} ${t('lastName')}`} className="form-control" />
                    <ErrorMessage name="lastName" component="div" className="text-danger" />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="phone">{t('phoneNumber')}*</label>
                <div className="input-group">
                  <FormPhoneInput name="phone" />
                </div>
                <ErrorMessage name="phone" component="div" className="text-danger" />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email">{t('email')}*</label>
                <Field name="email" placeholder={`${t('exampleAbrr')} ${t('emailDir')}`} className="form-control" />
                <ErrorMessage name="email" component="div" className="text-danger" />
              </div>
            </div>
            <div className={styles.FormFooter}>
              <div className="form-group mb-3 text-center">
                <button type="submit" className="btn btn-primary" disabled={!isValid || !dirty || isSubmitting}>
                  {isSubmitting ? <Loader /> : submitText ?? t('submit')}
                </button>
              </div>
              <div className="form-group mb-3 text-center">
                <label className={styles.FormHint}>{t('termsAndConditionsHint')} <Link href={termsAndConditionsLink ? termsAndConditionsLink : '/'} target="_blank">{t('termsAndConditions')}</Link>.</label>
              </div>
            </div>
          </Form>
        )
      }}

    </Formik>
    ) : (
    <div className={styles.FormThank}>
      <IconCheck className={styles.FormThankCheck} />
      <div className={styles.FormThankHeadline}>{t('thankYouHeadline')}</div>
      <div className={styles.FormThankTagline}>{t('thankYouTagline')}</div>
      <div className={styles.FormThanksCounterdown}>
        {t('thankYoucounterdownMessage')} {seconds} {t('seconds')}
      </div>
    </div>
    )
  );

};