import React from 'react';
import { renderImages } from '@/utils';
import styles from './ImageBox.module.scss';
import { ImageBox as ImageBoxDefaultProps } from "@incarail/killa-common";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const ImageBoxThird: React.FC<ImageBoxDefaultProps> = ({ headline, link, linkText, content, images }) => {
  return (
    <span className={styles.ImageBoxInner}>
      {renderImages(styles.ImageBoxBackground, headline, images, 'WEBP', 75)}
      <span className={styles.ImageBoxContent}>
        {headline && (<h3 className={styles.ImageBoxHeadline} dangerouslySetInnerHTML={{ __html: headline }}></h3>)}
        {content && (<span className={styles.ImageBoxText}>
          {documentToReactComponents(content)}
        </span>)}
        {(link && linkText) && (
          <span className={["btn", "btn-danger"].join(' ')}>{linkText}</span>
        )}
      </span>
    </span>
  );
};

