import { BaseNavigation } from "./navigation/BaseNavigation";
import { LocaleSelector } from "../base/LocaleSelector";
import { LogoConfigProps, Navigation as NavigationDefaultProps } from "@incarail/killa-common";

import styles from '@/styles/header/BaseHeader.module.scss'
import Link from "next/link";
import Image from "next/image";

interface BaseHeaderProps  {
  logo?: LogoConfigProps,
  navigation: NavigationDefaultProps,
  pageUrls?: Record<string, string>,
}

export const BaseHeader: React.FC<BaseHeaderProps> = ({ logo, navigation, pageUrls }) => {

  return (
    <header className={styles.BaseHeader}>
      <div className={styles.BaseHeaderInner}>
        <div className={styles.SiteLogo}>
          <Link href={logo?.url ?? '/'}>
            <Image src={logo?.imageUrl ?? "/assets/images/logo-ir-w.svg"} alt="Inca Rail" width={logo?.imageWidth ?? 153} height={logo?.imageHeight ?? 41} />
          </Link>
        </div>
        {navigation?.fields?.children?.length > 0 && <BaseNavigation {...navigation} />}
        {pageUrls && <div className={styles.LocaleSelectWrapper}><LocaleSelector pageUrls={pageUrls} /></div>}
      </div>
    </header>
  );
};