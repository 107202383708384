import { Ribbon as RibbonDefaultProps } from "@incarail/killa-common";
import { PromoImage } from "@/components/base";
import Link from "next/link";

import styles from "./Ribbon.module.scss"

export const Ribbon = (props: RibbonDefaultProps) => {
  const { link, openNewWindow } = props;

  let linkEle = null;

  try {
    const url = new URL(link || ''); // create URL object
    linkEle = (
      <Link href={url.href} target={openNewWindow ? '_blank' : '_self'}>
        <PromoImage {...props}></PromoImage>
      </Link>
    );
  } catch (e) {
    // link is not a valid URL, do not render Link element
    linkEle = <PromoImage {...props}></PromoImage>;
  }

  return (
    <div className={styles.Ribbon}>
      {linkEle}
    </div>
  );
};
