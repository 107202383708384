import { useEffect, useMemo, useState } from "react";
import Link from "next/link";
import { PromoImage, Counterdown } from "@/components/base";
import { useInView } from "@/hooks";
import { trackSelectPromotion, trackViewPromotion } from "@/utils";

import styles from "./Promotions.module.scss";
import { Promo } from "@incarail/killa-common";
import { Asset } from "contentful";
import { ViewPromotionData, SelectPromotionData } from "@/interfaces";
import { useRouter } from "next/router";

interface PromoCardProps {
    promo: Promo;
    index: number;
    pathname: string;
}

export const PromoCard = ({ promo, index, pathname }: PromoCardProps) => {
  const { locale } = useRouter();

  const { ref, isInView } = useInView(0.7);
  const [viewedPromo, setViewedPromo] = useState(false);

  const imageIdList = useMemo(() => promo.fields.images.map((i: Asset) => i.sys.id), [promo.fields.images]);

  const promotionData = useMemo(() => ({
    promotionId: promo.sys.id,
    promotionOrder: index + 1,
    promotionName: promo.fields.name ?? '',
    promotionImagesId: imageIdList.join(' :: '),
    promotionLink1: promo.fields.link,
    promotionType: promo.sys.contentType.sys.id,
    promotionLang: locale ?? '',
  }), [promo, index, locale, imageIdList]);

  useEffect(() => {
    if (isInView && !viewedPromo) {
      trackViewPromotion(promotionData);
      setViewedPromo(true);
    }
  }, [isInView, viewedPromo, promotionData]);

  const handlePromoClick = () => {
    const promotionData: SelectPromotionData = {
      promotionId: promo.sys.id,
      promotionOrder: index + 1,
      promotionName: promo.fields.name ?? '',
      promotionLinkSelected: promo.fields.link,
      promotionType: promo.sys.contentType.sys.id,
      promotionLang: locale ?? '',
    }
    trackSelectPromotion(promotionData);
  };

  return (
    <div ref={ref} className={styles.Promo}>
      {promo?.fields?.link ? (
        <Link className={styles.Promo} key={promo.sys.id} href={promo.fields.link} onClick={handlePromoClick}>
          <PromoImage {...promo.fields} />
          {(promo?.fields?.withCounterdown && promo?.fields.config?.fields.isScheduled) && (
            <Counterdown targetDate={new Date(promo.fields.config.fields.enabledTo)} />
          )}
        </Link>
      ) : (
        <div key={promo.sys.id} className={styles.Promo}>
          <PromoImage {...promo.fields} />
        </div>
      )}
    </div>
  );
};
