import { useEffect, useState } from "react";
import { Modal as ModalDefaultProps } from "@incarail/killa-common";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderImages } from "@/utils";
import Link from "next/link";

import cookie from 'js-cookie';
import slugify from 'slugify';

import styles from "./Modal.module.scss"
import CloseButton from '@/assets/icons/close.svg';
import { useTranslation } from 'next-i18next';
import { useRouter } from "next/router";

export const Modal = (props: ModalDefaultProps) => {
  const { name, content, images, link, delay, repeatEvery, triggerAt, stylesConfig } = props;

  const { locale } = useRouter();
  const { t } = useTranslation('form');

  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isVisible]);
  
  const renderModalContent = () => {
    return (
      <div className={styles.ModalBox}>
        <div className={styles.ModalContent}>
          {(stylesConfig?.modal?.type === 'image' && images) && (
          <div className={styles.ModalContentImage}>
            <Link href={link} target="_blank">
              {renderImages('', '', images, 'PNG', 75)}
            </Link>
          </div>
          )}
          {(stylesConfig?.modal?.type === 'content' && content) && (
          <div className={styles.ModalContentText}>
            {documentToReactComponents(content)}
          </div>
          )}
        </div>
      </div>
    );
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentCookie = cookie.get(slugify(name, { lower: true }));
      if(!currentCookie) {
        setIsVisible(prevIsVisible => !prevIsVisible);
        if(repeatEvery !== 0) {
          cookie.set(slugify(name, { lower: true }), '1', {
            expires: new Date(new Date().getTime() + repeatEvery * 60 * 1000),
          });
        }
      }
    }, delay*1000);

    return () => clearTimeout(timeout);
  }, [delay, name, repeatEvery]);

  return (
    <div className={[styles.Modal, (isVisible ? styles.ModalOpen : '')].join(' ')}>
      <div className={styles.ModalWrapper}>
        <button
          className={['btn', styles.ModalClose].join(' ')}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          <CloseButton />
        </button>
        {renderModalContent()}
      </div>
    </div>
    );
};