import { Button as ButtonDefaultProps } from "@incarail/killa-common";

import styles from "./Button.module.scss"

export const Button = (props: ButtonDefaultProps) => {
  const { label, link, openNewWindow, onClick, stylesConfig, sys } = props;

  const buttonStyles = stylesConfig?.button;

  const renderButton = () => {
    return link ? (
      <a className={buttonStyles?.classes} href={link} target={openNewWindow ? '_blank' : '_self'}  rel={openNewWindow ? 'noreferrer' : ''}>
        {label}
      </a>
    ) : (
      <button className={buttonStyles?.classes} onClick={onClick}>
        {label}
      </button>
    );
  }

  return (
    <>
      <div className={[`Button-${sys.id}`, styles.Button].join(' ')}>
        {renderButton()}
      </div>
      <style jsx>
      {`

        ${(buttonStyles?.mobile) ? `
          .${`Button-${sys.id}`} {
            ${(buttonStyles?.mobile?.align?.x) ? `
              justify-content: ${buttonStyles?.mobile?.align?.x};
            ` : ''}
          }
        ` : ''}

        @media (min-width: 768px) {
          ${(buttonStyles?.tablet) ? `
            .${`Button-${sys.id}`} {
              ${(buttonStyles?.tablet?.align?.x) ? `
                height: ${buttonStyles?.tablet?.align?.x};
              ` : ''}
            }
          ` : ''}
        @media (min-width: 992px) {
          ${(buttonStyles?.desktop) ? `
            .${`Button-${sys.id}`} {
              ${(buttonStyles?.desktop?.align?.x) ? `
                height: ${buttonStyles?.desktop?.align?.x};
              ` : ''}
            }
          ` : ''}
        }


      `}
      </style>
    </>
  );

};