import React from 'react';
import Image from 'next/image';
import { getContentfulImage, renderImages } from '@/utils';
import { ImageBox as ImageBoxDefaultProps } from "@incarail/killa-common";

import styles from './ImageBox.module.scss';

export const ImageBoxFirst: React.FC<ImageBoxDefaultProps> = ({ headline, images }) => {
  const iconButtonImage = images.find((i) => i.fields.title === 'icon-button');

  return (
    <span className={styles.ImageBoxInner}>
      <span className={styles.ImageBoxContent}>
        {headline && (<h3 className={styles.ImageBoxHeadline} dangerouslySetInnerHTML={{ __html: headline }}></h3>)}
        {iconButtonImage && 
          <span className={['btn', 'btn-danger', styles.ImageBoxIcon].join(' ')}>
            <Image
              className="img-fluid"
              src={getContentfulImage(iconButtonImage, { q: 75, fm: 'WEBP' }) ?? ''}
              alt={iconButtonImage.fields.description}
              width={iconButtonImage.fields.file.details.image?.width}
              height={iconButtonImage.fields.file.details.image?.height}
              loading="lazy"
            />
          </span>
        }
      </span>
      {renderImages(styles.ImageBoxBackground, headline, images, 'WEBP', 75)}
    </span>
  );
};

