import styles from "./Notification.module.scss"

type NotificationProps = {
  type?: string,
  message?: string,
};

export const Notification = ({ type, message }: NotificationProps) => {

  return (
    <div className={['alert', styles.Notification, `alert-${type}`].join(' ')}>
      <div>
        {message ?? 'There is an unexpected error'}
      </div>
    </div>
  );
};