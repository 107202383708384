import NavMenuExpander from '@/assets/icons/nav/menu-hamburger.svg';
import NavMenuClose from '@/assets/icons/nav/menu-close.svg';

import styles from '@/styles/header/BaseNavigation.module.scss'
import { Navigation as NavigationDefaultProps, NavigationItem } from '@incarail/killa-common';
import { ReactNode, useEffect, useState } from 'react';
import { BaseNavigationItem } from './BaseNavigationItem';
import { BaseNavigationItemLight } from './BaseNavigationItemLight';

import { useTranslation } from 'next-i18next'
const componentMap = {
  Light: BaseNavigationItemLight,
  Default: BaseNavigationItem,
} as const;

export const BaseNavigation: React.FC<NavigationDefaultProps> = (navigation) => {

  const { t } = useTranslation('navigation');
  const [showNavOnMobile, setShowNavOnMobile] = useState(false);
  const [ariaLabel, setAriaLabel] = useState('');

  useEffect(() => {
    setAriaLabel(t('navigationMenu'));
  }, [t]);

  type VersionType = keyof typeof componentMap;
  
  const version = (navigation?.fields?.version || 'Default') as VersionType;
  const NavigationItemComponent = componentMap[version];

  return (
    <nav 
      className={[
        styles.SiteNav, 
        styles[`SiteNav${version}`],
      ].join(' ')} 
      id={`navigation_${navigation.sys.id}`}
    >
      <button
        aria-label={ariaLabel || 'navigationMenu'}
        className={['btn', 'd-xl-none', styles.SiteNavCollapse].join(' ')}
        onClick={() => {
          setShowNavOnMobile(!showNavOnMobile);
        }}
      >
        {showNavOnMobile ? (<NavMenuClose />) : (<NavMenuExpander />)}
      </button>
      {navigation?.fields?.children?.length > 0 &&
      <ul className={showNavOnMobile ? 'd-flex' : ''}>
        {navigation.fields.children.reduce((nl: ReactNode[], ni: NavigationItem) => {
          nl.push(<NavigationItemComponent key={ni.sys.id} {...ni} />);
          return nl;
        }, [])}
      </ul>}
    </nav>
  );
};
