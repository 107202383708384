import React, { useState }  from 'react';
import { Tab, Tabs as TabsDefaultProps, TabsStylesConfig, tabsConfig } from "@incarail/killa-common";
import { useTranslation } from 'next-i18next';

import styles from "./Tabs.module.scss"
import { AccordionItem } from "./AccordionItem";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Search } from "@/components/base";

export const Tabs = (props: TabsDefaultProps) => {
  const { label, contents, withSearch, filteredBy, isAccordion, stylesConfig } = props;

  const { t } = useTranslation('common');

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const tabStyle = stylesConfig?.tabs?.style ?? '1';

  const withShadowMobile = stylesConfig?.tabs?.advanced?.mobile?.withShadow ?? false;
  const withShadowTablet = stylesConfig?.tabs?.advanced?.tablet?.withShadow ?? false;
  const withShadowDesktop = stylesConfig?.tabs?.advanced?.desktop?.withShadow ?? false;

  const renderFilteredContentList = (contentsList: Tab[], filterStatus: 'expired' | 'not-expired' | 'all') => {
    const today = new Date();
    const isExpired = (expiresAt: Date | null) => expiresAt ? expiresAt < today : false;

    return contentsList
      .filter((tab: Tab) => {
        const expiresAtDate = tab.fields.expiresAt ? new Date(tab.fields.expiresAt) : null;
        return filterStatus === 'expired' ? isExpired(expiresAtDate) : filterStatus === 'not-expired' ? !isExpired(expiresAtDate) : true;
      })
  };  
  
  const renderAccordion = (contents: Tab[], isMobileOnly = false): JSX.Element => {
    return (contents.length > 0) ? (
      <>
        {withSearch && <Search onSearch={setSearchQuery} />}
        <div className={
          [
            styles.Accordion, 
            withShadowMobile ? styles.WithShadowMobile : '', 
            withShadowTablet ? styles.WithShadowTablet : '', 
            withShadowDesktop ? styles.WithShadowDesktop : '', 
            tabStyle === '2' ? styles.AccordionLarge : '', 
            isMobileOnly ? 'd-block d-lg-none' : '',
            tabsConfig(stylesConfig as TabsStylesConfig)
          ].join(' ')}>
          {label && <div className={
            [
              styles.AccordionLabel,
              tabsConfig(stylesConfig as TabsStylesConfig, 'label'),
            ].join(' ')}>{label}</div>}
          <div className={styles.AccordionList}>
            {contents.map((tab: Tab) => (
              <AccordionItem key={tab.sys.id} {...tab.fields} />
            ))}
          </div>
        </div>
      </>
    ) : <></>;
  }

  const renderTabs = (contents: Tab[]): JSX.Element => {
    return (contents.length > 0) ? (
      <>
        {withSearch && <Search onSearch={setSearchQuery} />}
        <div className={
          [
            styles.Tabs,
            withShadowMobile ? styles.WithShadowMobile : '', 
            withShadowTablet ? styles.WithShadowTablet : '', 
            withShadowDesktop ? styles.WithShadowDesktop : '', 
            'd-none d-lg-block'
          ].join(' ')}>
          <div className={styles.TabHeader}>
            {contents.map((tab: Tab, ti) => (
              <button type="button" key={tab.sys.id} className={styles.TabHead} onClick={() => {
                setActiveIndex(ti)
              }}>
                <div className={styles.TabHeadLink}>
                  {tab.fields.headline}
                </div>
              </button>
            ))}
          </div>
          {contents.map((tab, ti) => (
            <div key={tab.sys.id} className={[styles.TabContent, (activeIndex === ti ? styles.TabOpen : '')].join(' ')}>
              {tab.fields.content && documentToReactComponents(tab.fields.content)}
            </div>
          ))}
        </div>
        {renderAccordion(contents, true)}
      </>
    ) : <></>;
  }

  const renderContent = (contents: Tab[]) => {
    return isAccordion ? (
      renderAccordion(contents)
    ) : (
      renderTabs(contents)
    );
  }

  return renderContent(renderFilteredContentList(contents, filteredBy ?? 'all'));

};