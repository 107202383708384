import { SplideSlider } from "@/components/base";
import { SplideSlide } from '@splidejs/react-splide';
import { Grid } from '@splidejs/splide-extension-grid';

import { HeroStylesConfig, TravelPackageItem as TravelPackageItemDefaultProps, TravelPackageCategoryGroup as TravelPackageCategoryGroupDefaultProps, BadRequestError, TravelPackagerFilter } from "@incarail/killa-common";
import { TravelPackagePot } from "../TravelPackagePot/TravelPackagePot";

import ContentLoader from 'react-content-loader';

import styles from "../TravelPackages.module.scss"

interface TravelPackageGridDefaultProps {
  travelPackageCategories: TravelPackageCategoryGroupDefaultProps[] | null,
  travelPackages: TravelPackageItemDefaultProps[] | null,
  travelPackageFilter: TravelPackagerFilter,
  isLoading: boolean,
}

const TravelPackagesGridLoader = () => (
  <>
    <ContentLoader 
      uniqueKey={"travel_carousel_image".split(' ').join().toLowerCase().replace(/,/g, '')}
      speed={2}
      width={288}
      height={275}
      viewBox="0 0 288 275"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="288" height="275" />
    </ContentLoader>
    <ContentLoader 
      uniqueKey={"travel_carousel_headline".split(' ').join().toLowerCase().replace(/,/g, '')}
      speed={2}
      width={288}
      height={60}
      viewBox="0 0 288 60"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="288" height="60" />
    </ContentLoader>
    <ContentLoader 
      uniqueKey={"travel_carousel_rating".split(' ').join().toLowerCase().replace(/,/g, '')}
      speed={2}
      width={288}
      height={25}
      viewBox="0 0 288 25"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="288" height="25" />
    </ContentLoader>
    <ContentLoader 
      uniqueKey={"travel_carousel_pricing".split(' ').join().toLowerCase().replace(/,/g, '')}
      speed={2}
      width={288}
      height={45}
      viewBox="0 0 288 45"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="288" height="45" />
    </ContentLoader>
  </>
);

export const TravelPackageGrid = ({ travelPackageCategories, travelPackages, travelPackageFilter, isLoading }: TravelPackageGridDefaultProps) => {

  const defaultStyles: HeroStylesConfig = {
    hero: {
      arrows: false,
      arrowPath: '',
      autoplay: false,
      autoHeight: false,
      autoWidth: false,
      breakpoints: {
        1699: {
          grid: {
            rows: 2,
            cols: 4,
          },
        },
        1599: {
          grid: {
            rows: 2,
            cols: 4,
          },
        },
        991: {
          grid: {
            rows: 2,
            cols: 3,
          },
        },
        767: {
          grid: {
            rows: 1,
            cols: 2,
          },
        },
        459: {
          padding: { left: 0, right: '4rem' },
          grid: {
            rows: 1,
            cols: 1,
          },
        }
      },
      classes: {
        arrows: 'splide__arrows ir-arrows',
        arrow: 'splide__arrow ir-arrow',
        prev: 'splide__arrow--prev ir-prev',
        next: 'splide__arrow--next ir-next',
        pagination: 'splide__pagination ir-pagination',
        page: 'splide__pagination__page ir-dot',
      },
      direction: 'ltr',
      drag: 'free',
      focus: 0,
      gap: '1rem',
      grid: {
        rows: 2,
        cols: 4,
        gap: {
          row: '1rem',
          col: '1rem',
        }
      },
      height: '100%',
      interval: 5000,
      keyboard: false,
      lazyLoad: false,
      noDrag: 'input, textarea, .no-drag',
      padding: 0,
      pagination: false,
      pauseOnFocus: true,
      pauseOnHover: true,
      preloadPages: 1,
      releaseWheel: false,
      rewind: true,
      speed: 400,
      start: 0,
      type: 'slide',
      wheel: false,
      width: '100%',
    }
  }

  const renderTravelPackageLoading = () => (
    <div className={styles.TravelPackageGridLoading}>
      <div className={[styles.TravelPackageItem].join(' ')}>
        <TravelPackagesGridLoader />
      </div>
      <div className={[styles.TravelPackageItem].join(' ')}>
        <TravelPackagesGridLoader />
      </div>
      <div className={[styles.TravelPackageItem].join(' ')}>
        <TravelPackagesGridLoader />
      </div>
      <div className={[styles.TravelPackageItem].join(' ')}>
        <TravelPackagesGridLoader />
      </div>
    </div>
  )

  return (
    (!isLoading && Array.isArray(travelPackages) && travelPackageCategories) ? (
        <div className={styles.TravelPackageGrid}>
          <SplideSlider
            key={travelPackageFilter.defaultCategoryId} 
            className={[styles.Hero].join(' ')}
            options={defaultStyles.hero}
            extensions={{ Grid }}
          >
            {travelPackages
              .sort((a, b) => parseInt(a.travelPackageOrdered) - parseInt(b.travelPackageOrdered)) // ASC sorting
              .map((tp: any) =>(
              <SplideSlide key={tp.travelPackageName}>
                <TravelPackagePot {...tp} />
              </SplideSlide>
            ))}
          </SplideSlider>
        </div>
    ) : (
      renderTravelPackageLoading()
    )
  )

}