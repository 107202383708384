
import { useTranslation } from 'next-i18next';
import styles from "./Search.module.scss"
import { Tab } from '@incarail/killa-common';
import IconSearch from '@/assets/icons/search.svg';

type SearchProps = {
  placeholder?: string,
  query?: string,
};

export const Search = ({ placeholder, query, onSearch }: SearchProps & { onSearch: (query: string) => void }) => {

  const { t } = useTranslation('search');
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  return (
    <div className={[styles.Search].join(' ')}>
      <div className="input-group input-group-lg mb-3">
        <input
          type="text"
          className="form-control"
          placeholder={placeholder ?? t('search')}
          aria-label={placeholder ?? t('search')}
          aria-describedby="search"
          onChange={handleSearchChange}
        />
        <span className="input-group-text" id="search">
          <IconSearch className={styles.SearchIcon} />
        </span>
      </div>
    </div>
  );
};