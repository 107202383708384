import { NavigationItem as NavigationItemDefaultProps } from "@incarail/killa-common";
import { ReactNode } from 'react';
import { BaseNavigationItemLink } from './BaseNavigationItemLink';

export const BaseNavigationItemSubItem: React.FC<NavigationItemDefaultProps> = (ni) => {

  const renderSubNavItem = (nav: NavigationItemDefaultProps[], isFirstLevel: boolean = false) => (<>
    {nav.reduce((nl: ReactNode[], ni: NavigationItemDefaultProps) => {
      nl.push(<BaseNavigationItemLink key={ni.sys.id} {...ni} />);
      return nl;
    }, [])}
  </>)

  return (
    <li key={ni.sys.id}>
      <BaseNavigationItemLink {...ni} />
      {ni?.fields?.children?.length > 0 && (<>
        {renderSubNavItem(ni.fields.children)}
      </>)}
    </li>
  )

}