import { TravelPackageCategory, TravelPackageCategoryGroup as TravelPackageCategoryGroupDefaultProps, TravelPackagerFilter } from '@incarail/killa-common';

import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';

import styles from "./TravelPackageFilterCategory.module.scss"
import { useState } from 'react';

interface TravelPackageFilterDefaultProps {
  travelGroup: TravelPackageCategoryGroupDefaultProps,
  travelPackageFilter: TravelPackagerFilter,
}

export const TravelPackageFilterCategory = ({ travelGroup }: TravelPackageFilterDefaultProps) => {

  const [showFilterContent, setShowFilterContent] = useState(false);

  return (
    <div className={[styles.TravelPackageFilterCategory, showFilterContent ? styles.TravelPackageFilterCategoryActive : ''].join(' ')}>
      <div
        className={styles.TravelPackageFilterCategoryHeadline}
        onClick={() => {
          setShowFilterContent(!showFilterContent);
        }}
      >
        {travelGroup.groupName}
        <IconChevronDown />
      </div>
      <div className={styles.TravelPackageFilterCategoryContent}>
        {travelGroup?.groupCategories?.map((category: TravelPackageCategory) => (
          <div key={category.categoryId} className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={category.categoryId}
              name={`category_${category.categoryId}`}
              id={`category_${category.categoryId}`}
              onChange={() => {
              }} 
            />
            <label className="form-check-label" htmlFor={`category_${category.categoryId}`}>
              {category.categoryName}
            </label>
          </div>          
        ))}
      </div>
    </div>
  )
}