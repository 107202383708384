import { ReactNode } from "react";
import { Navigation as NavigationDefaultProps, NavigationItem } from "@incarail/killa-common";

import { BaseNavigationItem } from "../navigation/BaseNavigationItem";

import styles from '@/styles/header/BaseTopNavigation.module.scss'
import { LocaleSelector } from "@/components/base/LocaleSelector";

interface BaseTopNavigationProps  {
  topNavigation: NavigationDefaultProps,
  infoNavigation: NavigationDefaultProps,
  pageUrls?: Record<string, string>,
}

export const BaseTopNavigation: React.FC<BaseTopNavigationProps> = ({ topNavigation, infoNavigation, pageUrls }) => {

  return (
    <div className={styles.BaseTopNavigation}>
      <div className={styles.BaseTopNavigationInner}>
        {topNavigation?.fields?.children?.length > 0 &&
        <div className={styles.SiteTopNav}>
          <ul>
            {topNavigation.fields.children.reduce((nl: ReactNode[], ni: NavigationItem) => {
              nl.push(<BaseNavigationItem key={ni.sys.id} {...ni} />);
              return nl;
            }, [])}
          </ul>
        </div>}
        {infoNavigation?.fields?.children?.length > 0 &&
        <div className={styles.SiteInfoNav}>
          <ul>
            {infoNavigation.fields.children.reduce((nl: ReactNode[], ni: NavigationItem) => {
              nl.push(<BaseNavigationItem key={ni.sys.id} {...ni} />);
              return nl;
            }, [])}
          </ul>
          {pageUrls && <LocaleSelector pageUrls={pageUrls} />}
        </div>}
      </div>
    </div>
  );
};