import { useRouter } from "next/router";
import { useTranslation } from 'next-i18next';
import styles from "./Breadcrumb.module.scss"

type BreadcrumbProps = {
  path?: string;
  title?: string,
};

type BreadcrumbItem = {
  position: number;
  name: string;
  path?: string;
};

export const Breadcrumb = ({ title, path }: BreadcrumbProps) => {

  const { locale } = useRouter();

  const { t } = useTranslation('breadcrumb');

  const breadcrumbItems: BreadcrumbItem[] = [
    {
      position: 1,
      name: t('home'),
      path: `${process.env.KILLA_URL}/${locale}`
    },
    {
      position: 2,
      name: title ?? '',
      path: `${process.env.KILLA_URL}/${locale}${path}`
    },
  ]
  
  const breadcrumbJson = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbItems.map((item) => ({
      "@type": "ListItem",
      "position": item.position,
      "name": item.name,
      "item": item.path,
    })),
  });

  return (
    <>
      <nav aria-label="Breadcrumb" role="navigation" className={styles.Breadcrumb}>
        <div className="container">
          <div className="row">
            <div className="col">
              <ol className={styles.BreadcrumbList} itemScope itemType="http://schema.org/BreadcrumbList">
                {breadcrumbItems.map((item, index) => (
                  <li 
                    key={item.position} 
                    className={`${styles.BreadcrumbListItem} ${index === breadcrumbItems.length - 1 ? styles.BreadcrumbListItemActive : ''}`} 
                    itemProp="itemListElement" 
                    itemScope 
                    itemType="http://schema.org/ListItem"
                  >
                    <a href={item.path} itemProp="item">
                      <span itemProp="name">{item.name}</span>
                    </a>
                    <meta itemProp="position" content={item.position.toString()} />
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </nav>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: breadcrumbJson }} />
    </>
  );
};


