import { useCallback, useEffect, useRef, useState } from "react";
import { Booker as BookerDefaultProps, BookerJourneyTypesPropsConfig, BookerTicketsPropsConfig } from "@incarail/killa-common";
import { Formik } from "formik";
import { addDays, format } from "date-fns";
import { useTranslation } from 'next-i18next';

import styles from "./BookerClassic.module.scss";
import { useTicketsFormSchema } from "@/hooks/useTicketFormSchema";
import { Notification } from "../../base";
import { SelectList, SelectDate, SelectPassengers } from "@/components/form/Select";
import IconTrain from '@/assets/icons/train.svg';
import IconTravelPackage from '@/assets/icons/packages.svg';

import { useRouter } from "next/router";
import { useBookerContext } from '@/context/BookerContext';

export const BookerClassic = (props: BookerDefaultProps) => {
  const { bookerConfig } = props;
  const { tickets, travelPackages, availableJourneys } = bookerConfig;

  const { locale, push } = useRouter();

  const { t } = useTranslation('form');
  const ticketsFormSchema = useTicketsFormSchema();

  const [ticketsFormData, setTicketsFormData] = useState<BookerTicketsPropsConfig>({
    journey_types: 0,
    route_id: 5,
    from: addDays(Date.now(), 1),
    to: addDays(Date.now(), 1),
    currency: 'USD',
    passengers: {
      adults: 2,
      children: 0,
      babies: 0,
    }
  });

  const [isClientSide, setIsClientSide] = useState(false);

  const { isActive, activateBooker, deactivateBooker } = useBookerContext();

  const bookerRef = useRef<HTMLDivElement>(null);

  const checkAndScrollToBooker = useCallback(() => {
    if (bookerRef.current) {
      const viewportHeight = window.innerHeight;
      const bookerObject = bookerRef.current.getBoundingClientRect();
      const desiredOffset = viewportHeight * 0.10;
      const scrollY = bookerObject.top + window.scrollY - desiredOffset;
      window.scrollTo({ top: scrollY, behavior: 'smooth' });
      activateBooker();
    }
  }, [activateBooker]);

  const defaultJourneys = [{
      label: t('roundTrip'),
      value: 0
    }, {
      label: t('outwardJourney'),
      value: 1
    }, {
      label: t('returnJourney'),
      value: 2
    },
  ];

  useEffect(() => {
    setIsClientSide(true);
  }, []);

  useEffect(() => {
    if (isActive) {
      checkAndScrollToBooker();
    }
  }, [isActive, checkAndScrollToBooker]);

  return (
    <div
      id="Booker"
      ref={bookerRef}
      className={[styles.Booker, isActive ? styles.BookerInteract : ''].join(' ')}
    >
      <div className={styles.BookerInner}>
        <div className={styles.BookerTabs}>
          <button
            type="button"
            className={['btn', styles.BookerTab, (tickets?.isDark ? styles.BookerTabDark : ''), styles.BookerTabActive].join(' ')}
          >
            <IconTrain />
            <span>
              {t('trains')}
            </span>
          </button>
          <a
            target={travelPackages?.openNewWindow ? '_blank' : '_self'}
            rel={travelPackages?.openNewWindow ? 'noreferrer' : ''}
            className={['btn', styles.BookerTab, (travelPackages?.isDark ? styles.BookerTabDark : '')].join(' ')}
            href={locale === 'es' ? `https://incarail.com/${locale}/tours` : `https://incarail.com/${locale}/tours`}
          >
            <IconTravelPackage />
            <span>
              {t('travelPackages')}
            </span>
          </a>
        </div>
        <div className={[styles.BookerForm, styles.BookerFormTickets, (tickets?.isDark ? styles.BookerFormDark : '')].join(' ')}>
          <Formik
            initialValues={ticketsFormData}
            validationSchema={ticketsFormSchema}
            validateOnChange={true}
            validateOnMount={false}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {

              const submitUrl = `https://zonasegura.incarail.com/itinerario/buscar/idViajeSentido/${ticketsFormData.journey_types}${(ticketsFormData.from && ticketsFormData.journey_types !== 2) ? `/fecViajeIda/${format(ticketsFormData.from, 'ddMMyyyy')}` : ''}${(ticketsFormData.to && ticketsFormData.journey_types === 0) ? `/fecViajeRegreso/${format(ticketsFormData.to, 'ddMMyyyy')}` : ''}${(ticketsFormData.journey_types === 2) ? `/fecViajeRegreso/${format(ticketsFormData.from, 'ddMMyyyy')}` : ''}/numCupoAdulto/${ticketsFormData.passengers.adults}/numCupoNino/${ticketsFormData.passengers.children}/numCupoInfante=${ticketsFormData.passengers.babies}?language=${locale}`;

              if(tickets?.openNewWindow) {
                window.open(submitUrl, '_blank');
                window.location.href = locale === 'es' ? `${process.env.KILLA_URL}/${locale}/tours?redirectBooker=1` : `${process.env.KILLA_URL}/${locale}/tours?redirectBooker=1`;
              } else {
                push(submitUrl);
              }

              setSubmitting(false);
              deactivateBooker();
            }}
          >
            {({
              errors,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              values,
              validateField,
            }) => {
              return (
                <form
                  target="_blank"
                  method="get"
                  onSubmit={handleSubmit}
                  className={[styles.BookerFormInner].join(' ')}
                >
                  <div className={styles.BookerFormNotification}>
                    {errors.journey_types &&
                      <Notification type="danger" message={t('errors.journeyTypeRequired')} />}
                    {errors.from &&
                      <Notification type="danger" message={t('errors.fromDateRequired')} />}
                    {errors.to &&
                      <Notification type="danger" message={t('errors.toDateRequired')} />}
                  </div>
                  <div className={['form-group', styles.BookerFormGroup, styles.BookerFormGroupJourney].join(' ')}>
                    <div className="d-none d-lg-block">
                      {(availableJourneys ?? defaultJourneys).map((journey: BookerJourneyTypesPropsConfig) => (
                        <div className="form-check form-check-inline" key={journey.label}>
                          <label className="form-check-label" htmlFor={`journey_type_${journey.value}`}>
                            <input
                              className="form-check-input"
                              checked={ticketsFormData.journey_types === journey.value}
                              type="radio"
                              name="journey_type"
                              id={`journey_type_${journey.value}`}
                              value={journey.value}
                              onChange={(e) =>
                                setTicketsFormData(ticketsFormData => ({
                                  ...ticketsFormData,
                                  journey_types: parseInt(e.target.value)
                                }))
                              }
                            />
                            {journey.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="d-lg-none">
                      <label className={styles.BookerFormLabel}>{t('journeyType')}:</label>
                      <SelectList
                        label={t('chooseJourneyType')}
                        onSelect={(option) => {
                          setTicketsFormData(ticketsFormData => ({
                            ...ticketsFormData,
                            journey_types: option.value as number
                          }))
                        }}
                        options={availableJourneys ?? defaultJourneys} />
                    </div>
                  </div>
                  <div className={['form-group', styles.BookerFormGroup, styles.BookerFormGroupDates].join(' ')}>
                    <label className={styles.BookerFormLabel}>{t('travelDates')}:</label>
                    {isClientSide && (<SelectDate
                      label={t('chooseTravelDates')}
                      isRoundTrip={ticketsFormData.journey_types === 0}
                      onSelect={(option) => {
                        setTicketsFormData(ticketsFormData => ({
                          ...ticketsFormData,
                          from: option.from,
                          to: option.to ?? option.from,
                        }));
                      }}
                      enabledTo={bookerConfig.enabledFrom ?? undefined}
                      enabledFrom={bookerConfig.enabledFrom ?? new Date()} />)}
                  </div>
                  <div className={['form-group', styles.BookerFormGroup, styles.BookerFormGroupPassengers].join(' ')}>
                    <label className={styles.BookerFormLabel}>{t('passengers')}:</label>
                    <SelectPassengers
                      label={t('choosePassengers')}
                      onSelect={(option) => {
                        setTicketsFormData(ticketsFormData => ({
                          ...ticketsFormData,
                          passengers: option
                        }))
                      }}
                      options={ticketsFormData.passengers} />
                  </div>
                  <div className={['form-group', styles.BookerFormGroup, styles.BookerFormGroupAction].join(' ')}>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={['btn', styles.BookerFormSubmit].join(' ')}
                    >
                      {t('searchTickets')}
                    </button>
                  </div>
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
      <div className={styles.BookerBack}></div>
    </div>
  );
};
