import React from 'react';
import { ImageBox as ImageBoxDefaultProps } from "@incarail/killa-common";
import { getContentfulImage } from '@/utils';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Image from 'next/image';
import styles from './ImageBox.module.scss';

export const ImageBoxSecond: React.FC<ImageBoxDefaultProps> = ({ headline, content, images }) => {
  const iconImage = images.find((i) => i.fields.title === 'icon');

  return (
    <span className={[styles.ImageBoxInner].join(' ')}>
      <span className={[styles.ImageBoxContent].join(' ')}>
        {iconImage && (
          <span className={styles.ImageBoxIcon}>
            <Image
              src={getContentfulImage(iconImage, { q: 75, fm: 'WEBP' }) ?? ''}
              alt={iconImage.fields.description}
              width={iconImage.fields.file.details.image?.width}
              height={iconImage.fields.file.details.image?.height}
              loading="lazy"
            />
          </span>
        )}
        {headline && (<h3 className={styles.ImageBoxHeadline} dangerouslySetInnerHTML={{ __html: headline }}></h3>)}

        {content && (
          <span className={[styles.ImageBoxText].join(' ')}>
            {documentToReactComponents(content)}
          </span>
        )}
      </span>
    </span>
  );
};
