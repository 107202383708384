import React, { useState, useEffect, useRef } from 'react';
import { FieldProps, getIn } from 'formik';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import styles from './BookerDatePicker.module.scss';
import IconCalendar from '@/assets/icons/calendar.svg';
import { useRouter } from 'next/router';
import { addDays, addYears, endOfYear, format } from "date-fns";
import { es, enUS, ptBR, fr} from 'date-fns/locale';
import { useDeviceType } from '@/hooks/useDeviceType';

interface BookerDatePickerProps extends FieldProps {
  placeholderFrom: string;
  placeholderTo?: string;
  className?: string;
  withRange?: boolean;
}

const localesMap: { [key: string]: Locale } = {
  en: enUS,
  es: es,
  fr: fr,
  pt: ptBR,
};

export const BookerDatePicker: React.FC<BookerDatePickerProps> = ({
  field,
  form,
  placeholderFrom,
  placeholderTo,
  className,
  withRange = true,
}) => {
  const [from, setFrom] = useState<Date | undefined>(field.value?.from ?? addDays(Date.now(), 1));
  const [to, setTo] = useState<Date | undefined>(field.value?.to ?? addDays(Date.now(), 1));
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const { locale } = useRouter();

  const [clickingSuggestion, setClickingSuggestion] = useState(false);

  const deviceType = useDeviceType();

  const onBlur = () => {
    if (clickingSuggestion) return;
    setShowCalendar(false);
  };

  useEffect(() => {
    if (field.value) {
      setFrom(field.value.from);
      setTo(field.value.to);
    }
  }, [field.value]);

  useEffect(() => {
    if (from && (to || !withRange)) {
      setShowCalendar(false);
    }
    if(!withRange) {
      setTo(from);
      form.setFieldValue('to', from);
    }
  }, [from, to, withRange]);

  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const hasError = touch && error;

  const onChange = (date: Date | [Date | null, Date | null]) => {
    if (Array.isArray(date)) {
      const [start, end] = date;
      setFrom(start || undefined);
      setTo(end || undefined);
      form.setFieldValue('from', start || null);
      form.setFieldValue('to', end || null);
    } else {
      setFrom(date);
      form.setFieldValue('from', date || null);
    }
    setClickingSuggestion(false);
  };

  return (
    <div className={styles.BookerCalendar}>
      <div
        className={['input-group'].join(' ')}
        onBlur={onBlur}
        onClick={(event) => {
        if (calendarRef.current && calendarRef.current.contains(event.target as Node)) {
          event.stopPropagation();
        } else {
          if(withRange && !to) {
            setTo(from);
          }
          setShowCalendar(!showCalendar);
        }
      }}>
        <span className="input-group-text" id="departure_calendar">
          <IconCalendar />
        </span>
        <input
          type="text"
          value={from ? format(from, 'EEE, dd LLL', { locale: locale !== 'en' ? es : enUS }) : ''}
          placeholder={placeholderFrom}
          className={`form-control ${className ?? ''} ${hasError ? 'is-invalid' : ''}`}
          readOnly
        />
        {withRange && <>
          <span className="input-group-text" id="return_calendar">
            <IconCalendar />
          </span>
          <input
            type="text"
            value={to ? format(to, 'EEE, dd LLL', { locale: locale !== 'en' ? es : enUS }) : ''}
            placeholder={placeholderTo}
            className={`form-control ${className ?? ''} ${hasError ? 'is-invalid' : ''}`}
            readOnly
          />
        </>}
    
        {hasError && <div className="invalid-feedback">{error}</div>}
      </div>
      {showCalendar && (
        <div
          className={[styles.BookerCalendarWrapper, withRange && deviceType === 'desktop' ? styles.BookerCalendarRangeWrapper : ''].join(' ')}
          onMouseDown={() => setClickingSuggestion(true)}
          ref={calendarRef}
        >
          <DatePicker
            locale={localesMap[locale ?? 'en']}
            dateFormat={locale !== 'en' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
            endDate={withRange ? to : null}
            onChange={onChange}
            minDate={addDays(Date.now(), 1)}
            maxDate={addYears(endOfYear(new Date()), 1)}
            monthsShown={withRange && deviceType === 'desktop' ? 2 : 1}
            selected={from}
            startDate={from}
            selectsRange={withRange}
            inline
          />      
        </div>
        )}
    </div>
  );
  
};
