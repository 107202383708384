import { NavigationItem as NavigationItemDefaultProps } from "@incarail/killa-common";
import { ReactNode, useRef, useState, useEffect } from 'react';
import { BaseFooterNavigationItemLink } from './BaseFooterNavigationItemLink';
import { BaseFooterNavigationItemSub } from "./BaseFooterNavigationItemSub";

import styles from '@/styles/footer/BaseFooter.module.scss'

export const BaseFooterNavigationItem: React.FC<NavigationItemDefaultProps> = (ni) => {

  const [showSubNav, setShowSubNav] = useState(false);

  const toggleNav = () => {
    setShowSubNav(!showSubNav);
  }
  
  return (
    <li key={ni.sys.id} className={[(showSubNav ? styles.FooterSectionNavExpanded : '')].join(' ')}>
      <BaseFooterNavigationItemLink {...ni} openHandler={toggleNav} />
      {ni?.fields?.children?.length > 0 && (
        <span className={[styles.FooterSectionNavSub].join(' ')}>
          {ni.fields.children.length > 0 &&
          <ul>
            {ni.fields.children.reduce((nl: ReactNode[], ni: NavigationItemDefaultProps) => {
              nl.push(<BaseFooterNavigationItemSub key={ni.sys.id} {...ni} />);
              return nl;
            }, [])}
          </ul>}
        </span>
      )}
    </li>
  )

}