import { NavigationItem as NavigationItemDefaultProps } from "@incarail/killa-common";
import { ReactNode, useRef, useState, useEffect } from 'react';
import { BaseNavigationItemLink } from './BaseNavigationItemLink';
import { BaseNavigationItemSub } from "./BaseNavigationItemSub";

import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';
import IconArrowLeft from '@/assets/icons/arrows/arrow-left.svg';
import styles from '@/styles/header/BaseNavigation.module.scss'
import { useTranslation } from 'next-i18next';

export const BaseNavigationItem: React.FC<NavigationItemDefaultProps> = (ni) => {

  const { t } = useTranslation('navigation');
  const [showSubNavMobile, setShowSubNavOnMobile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const refSubNav = useRef<HTMLSpanElement>(null);
  
  useEffect(() => {
    refSubNav?.current?.classList.toggle('d-flex', showSubNavMobile);
  }, [showSubNavMobile]);

  return (
    <li
      key={ni.sys.id}
      className={showMenu ? styles.SiteNavOpen : ''}
      onMouseEnter={() => {
        setShowMenu(!showMenu);
      }}
      onMouseLeave={() => {
        setShowMenu(!showMenu);
      }}
    >
      <BaseNavigationItemLink
       {...ni}
       onClick={() => setShowMenu(false)}
      />
      {ni?.fields?.children?.length > 0 && (
      <IconChevronDown
        className={styles.SiteNavIconMobile}
        onClick={() => {
          setShowSubNavOnMobile(!showSubNavMobile);
        }}
      />
      )}
      {ni?.fields?.children?.length > 0 && (
        <span className={[styles.SiteNavSub].join(' ')} ref={refSubNav}>
          <span className={styles.SiteNavSubInner}>
            <button
              className={['btn', 'd-lg-none', styles.SiteNavSubButton].join(' ')}
              onClick={() => {
                setShowSubNavOnMobile(!showSubNavMobile);
              }}
            >
              <IconArrowLeft />
              {t('goBack')}
            </button>
            <span className={styles.SiteNavSubHeadline}>{ni.fields.name}</span>
            {ni.fields.children.length > 0 &&
            <ul>
              {ni.fields.children.reduce((nl: ReactNode[], ni: NavigationItemDefaultProps) => {
                nl.push(<BaseNavigationItemSub key={ni.sys.id} {...ni} />);
                return nl;
              }, [])}
            </ul>}
          </span>
        </span>
      )}
    </li>
  )

}