import Image, { ImageProps } from 'next/image';
import { useState } from 'react';

import styles from './MediaImage.module.scss'
import Loader from '../Loader/Loader';

const MediaImage: React.FC<ImageProps> = ({ src, alt, width, height, ...props }) => {
  const [isLoading, setLoading] = useState(true);

  return (
    <span className={styles.MediaImage}>
      {isLoading && <Loader />}
      <Image
        {...props}
        placeholder="blur"
        blurDataURL={src as string}
        unoptimized
        alt={alt}
        src={src}
        width={width ?? 0}
        height={height ?? 0}
        layout="fill"
        objectFit="cover"
        className={[
          'img-fluid',
          isLoading
            ? styles.MediaLoading
            : ''
        ].join(' ')}
        onLoadingComplete={() => setLoading(!isLoading)}
      />
    </span>
  );
}

export default MediaImage;