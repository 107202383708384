import React from "react";
import { renderImages } from "@/utils";
import { Asset } from "contentful";

import styles from './PromoImage.module.scss'

interface PromoImageProps {
  name: string,
  images: Asset[],
  imageFormat: string,
  imageCompression: number,
  withCaption?: boolean,
  isLazy?: boolean,
}

export const PromoImage = (props: PromoImageProps) => {

  const { name, images, imageFormat, imageCompression, withCaption, isLazy } = props;

  return (
    <>
      {renderImages(withCaption ? styles.PromoImageWithCaption : styles.PromoImage, name, images, imageFormat, imageCompression, withCaption, isLazy)}
    </>
  );
};