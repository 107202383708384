import { TravelPackageCategory, TravelPackageCategoryGroup as TravelPackageCategoryGroupDefaultProps, TravelPackagerFilter } from '@incarail/killa-common';
import ContentLoader from 'react-content-loader';

import styles from "../TravelPackages.module.scss"
import { SplideSlider } from '@/components/base';
import { SplideSlide } from '@splidejs/react-splide';
import { Grid } from '@splidejs/splide-extension-grid';
import { TravelPackagePot } from '../TravelPackagePot/TravelPackagePot';

interface TravelPackageCategoryBarDefaultProps {
  travelGroups: TravelPackageCategoryGroupDefaultProps | null
  travelPackageFilter: TravelPackagerFilter,
  onSelectFilter: (travelFilters: TravelPackagerFilter) => void,

  isLoading: boolean,
}

const TravelPackagesCategoryLoader = () => (
  <ContentLoader 
    uniqueKey={"loader".split(' ').join().toLowerCase().replace(/,/g, '')}
    speed={2}
    width={120}
    height={43}
    viewBox="0 0 120 43"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="120" height="43" />
  </ContentLoader>
);

export const TravelPackageCategoryBar = ({ travelGroups, travelPackageFilter, onSelectFilter, isLoading }: TravelPackageCategoryBarDefaultProps) => {

  const renderTravelPackageCategoriesLoading = () => (
    <div className={styles.TravelPackagesFilter}>
      <TravelPackagesCategoryLoader />
      <TravelPackagesCategoryLoader />
      <TravelPackagesCategoryLoader />
      <TravelPackagesCategoryLoader />
      <TravelPackagesCategoryLoader />
    </div>
  )

  return !isLoading ? (
    <div className={styles.TravelPackagesFilter}>
      <SplideSlider
        className={[styles.Hero].join(' ')}
        options={{
          arrows: false,
          autoWidth: true,
          drag: 'free',
          gap: '0.5rem',
          pagination: false,
        }}
      >
        {travelGroups?.groupCategories?.map((c: TravelPackageCategory) => (
          <SplideSlide
            key={`${c.categoryName}_${c.categoryId}`}
          >
            <button
              className={['btn', 'btn-outline-primary', (travelPackageFilter.defaultCategoryId === c.categoryId.toString()) ? 'active' : '', styles.TravelPackagesFilterButton].join(' ')}
              onClick={() => {
                onSelectFilter({
                  ...travelPackageFilter,
                  defaultCategoryId: c.categoryId.toString()
                })    
              }}
            >
              {c.categoryName ?? ''}
            </button>
          </SplideSlide>
          )
        )}
      </SplideSlider>
    </div>
    ) : (
      renderTravelPackageCategoriesLoading()
    )
}
