import styles from './Loader.module.scss'

const Loader = () => (
  <div className={styles.Loader}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31px"
      height="31px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#053220"
        strokeWidth="15"
        r="34"
        strokeDasharray="160.22122533307947 55.40707511102649">
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="0.9174311926605504s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  </div>
);

export default Loader;