import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';

import Link from 'next/link';

import { NavigationItem as NavigationItemDefaultProps } from "@incarail/killa-common";
import styles from '@/styles/header/BaseNavigation.module.scss'

export const BaseNavigationItemLink: React.FC<NavigationItemDefaultProps & { onClick?: () => void }> = ({ onClick, ...ni }) => !ni.fields.isExternal ? (
    <Link
      href={ni.fields.link}
      target={ni.fields.target !== 'Same Window' ? '_blank' : '_self'}
      className={ni.fields.isHeadline ? styles.SiteNavSubLinkTitle : ''}
      onClick={onClick}
    >
      {ni.fields.name}
      {ni?.fields?.children?.length > 0 && (<>
        <IconChevronDown className={styles.SiteNavIconDesktop} />
      </>)}
    </Link>
  ) : (
    <a href={ni.fields.link} rel={ni.fields.target !== 'Same Window' ? 'noreferrer' : ''} target={ni.fields.target !== 'Same Window' ? '_blank' : '_self'} className={ni.fields.isHeadline ? styles.SiteNavSubLinkTitle : ''}>
      {ni.fields.name}
      {ni?.fields?.children?.length > 0 && (<>
        <IconChevronDown className={styles.SiteNavIconDesktop} />
      </>)}
    </a>

  )