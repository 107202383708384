import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';
import Link from "next/link";
import styles from '@/styles/header/BaseNavigation.module.scss';

import { NavigationItem as NavigationItemDefaultProps } from "@incarail/killa-common";
import { ReactNode, useState } from "react";
import { BaseNavigationItemSubItem } from "./BaseNavigationItemSubItem";

export const BaseNavigationItemSub: React.FC<NavigationItemDefaultProps> = (ni) => {

  const [showSubNavOnMobile, setShowNavOnMobile] = useState(false);

  return (
    <li>
      {!ni.fields.isExternal ? (
        <Link href={ni.fields.link} target={ni.fields.target !== 'Same Window' ? '_blank' : '_self'} className={ni.fields.isHeadline ? styles.SiteNavSubLinkTitle : ''}>
          {ni.fields.name}
        </Link>
      ) : (
        <a href={ni.fields.link} rel={ni.fields.target !== 'Same Window' ? 'noreferrer' : ''} target={ni.fields.target !== 'Same Window' ? '_blank' : '_self'} className={ni.fields.isHeadline ? styles.SiteNavSubLinkTitle : ''}>
          {ni.fields.name}
        </a>
      )}
      {ni?.fields?.children?.length > 0 && (<>
        <IconChevronDown
          className={styles.SiteNavIconSubMobile}
          onClick={() => {
            setShowNavOnMobile(!showSubNavOnMobile);
          }}
        />
      </>)}
      {ni?.fields?.children?.length > 0 && (
        <ul className={showSubNavOnMobile ? 'd-flex' : ''}>
          {ni?.fields?.children.reduce((nl: ReactNode[], ni: NavigationItemDefaultProps) => {
            nl.push(<BaseNavigationItemSubItem key={ni.sys.id} {...ni} />);
            return nl;
          }, [])}
        </ul>
      )}
    </li>
  )
}