import Head from 'next/head';
import { Page as PageProps, Navigation as NavigationDefaultProps, Settings as SettingsDefaultProps, DEFAULT_LANGUAGE, Meta } from '@incarail/killa-common';
import PageNotFound from '@/pages/404';
import { BaseHeader, BaseTopNavigation, BaseFooter } from '@/components'
import { Breadcrumb } from '../base/Breadcrumb/Breadcrumb';
import DynamicRenderer from '../DynamicRender';

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import ContactForm from '../base/ContactForm/ContactForm';

interface BaseLayoutProps {
  metaConfig: Meta,
  pageContents: any,
  pageUrls: Record<string, string>
  navigation: NavigationDefaultProps,
  topNavigation: NavigationDefaultProps,
  infoNavigation: NavigationDefaultProps,
  settings: SettingsDefaultProps,
  messages: any,
  slug: string,
  isLocal: boolean,
}

export const BaseLayoutContact: React.FC<BaseLayoutProps> = ({
  metaConfig, pageContents, pageUrls, navigation, topNavigation, infoNavigation, settings, slug,
}) => {
  const { t } = useTranslation('layout');
  const { locale } = useRouter();

  const { fields: { footerConfig } } = settings;

  return (
    <>
      <Head>

        <title>{metaConfig?.fields?.title ?? ''} | Incarail</title>
        {Object.keys(pageUrls ?? {}).map((pu: string) => (
            <link key={pageUrls[pu]} rel="alternate" hrefLang={pu} href={pageUrls[pu]} />
          )
        )}
        {slug === '/home' && <link rel="alternate" href={process.env.KILLA_URL} hrefLang="x-default" />}
        {slug !== '/home' && <link rel="alternate" href={pageUrls[DEFAULT_LANGUAGE]} hrefLang="x-default" />}
        <link rel="canonical" href={pageUrls[locale ?? DEFAULT_LANGUAGE]} />
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#053220" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#053220" />

        <meta
          name="description"
          content={metaConfig?.fields?.description ?? ''}
        />
        <meta name="keywords" content={metaConfig?.fields?.keywords?.join(', ') ?? ''} />
        <meta
          name="theme-color"
          content={metaConfig?.fields?.themeColor ?? '#053220'}
        />
      </Head>
      <div className={[slug === '/home' ? 'HomePage' : 'Page'].join(' ')}>
        <BaseHeader navigation={navigation} />
        {metaConfig?.fields?.withBreadcrumb && 
        <Breadcrumb title={metaConfig.fields.title ?? t('noTitle')} path={slug} />}
        <main>
          {(metaConfig?.fields?.title && metaConfig?.fields?.withPageTitle) && 
            <header className="PageHeader">
              <h1 className="PageTitle">
                {metaConfig.fields.title}
              </h1>
            </header>
          }
          <div className="container">
            <div className="row">
              <div className="col-lg-6">

                <ContactForm />

              </div>
              <div className="col-lg-6">
                <DynamicRenderer contentArray={pageContents} />
              </div>
            </div>
          </div>

        </main>
        {footerConfig && 
        <BaseFooter config={footerConfig} />}
      </div>
    </>
  );
}