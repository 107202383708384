import dynamic from 'next/dynamic';

import { ComponentType } from 'react';
import { Button } from './Button/Button'
import { Hero } from './Hero/Hero'
import { Headline } from './Headline/Headline'
import { Ribbon } from './Ribbon/Ribbon'
import { Promotions } from './Promotions/Promotions';
import { HTMLContent } from './HTMLContent/HTMLContent';
import { RichContent } from './RichContent/RichContent';
import { Grid } from './Grid/Grid';
import { ImageBox } from './ImageBox/ImageBox';
import { Modal } from './Modal/Modal';
import { FormLead } from './Form/FormLead';
import { Tabs } from './Tabs/Tabs';
import { Spacer } from './Spacer/Spacer';
import { Booker } from './Booker/Booker';
import { TravelPackages } from './TravelPackages/TravelPackages';
import { TrainSchedule } from './TrainSchedule/TrainSchedule';

export interface ContentfulComponents<T = any> {
  [key: string]: ComponentType<T>;
}

const components: ContentfulComponents = {
  booker: Booker,
  button: Button,
  formLead: FormLead,
  hero: Hero,
  grid: Grid,
  headline: Headline,
  htmlContent: HTMLContent,
  imageBox: ImageBox,
  modal: Modal,
  promotions: Promotions,
  richContent: RichContent,
  tabs: Tabs,
  ribbon: Ribbon,
  spacer: Spacer,
  travelPackages: TravelPackages,
  trainSchedule: TrainSchedule,
};

export default components;