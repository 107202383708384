import { ReactNode } from 'react';
import { StylesConfig, layoutConfig } from '@incarail/killa-common';

type SectionProps = {
  children?: ReactNode,
  sysId: string,
  styles: StylesConfig
};

export const Section = ({ sysId, children, styles }: SectionProps) => {

  const containerClass = layoutConfig(styles);

  const mobileStyles = `
    ${styles?.advanced?.mobile?.flex?.alignItems ? `align-items: ${styles.advanced.mobile.flex.alignItems};` : ''}
    ${styles?.style?.mobile?.background?.color ? `background-color: ${styles.style.mobile.background.color ?? 'transparent'};` : ''}
    ${styles?.style?.mobile?.background?.image?.size ? `background-size: ${styles.style.mobile.background.image.size};` : ''}
    ${styles?.style?.mobile?.background?.image?.repeat ? `background-repeat: ${styles.style.mobile.background.image.repeat};` : ''}
    ${styles?.style?.mobile?.background?.image?.position ? `background-position: ${styles.style.mobile.background.image.position};` : ''}
    ${styles?.style?.mobile?.background?.image?.url ? `background-image: url('${styles.style.mobile.background.image.url}');` : ''}
    ${styles?.advanced?.mobile?.flex ? 'display: flex;' : ''}
    ${styles?.layout?.mobile?.height && styles.layout.mobile.height !== '-1' ? `height: ${styles.layout.mobile.height};` : ''}
  `.trim().replace(/\s+/g, ' ');

  const tabletStyles = `
    ${styles?.advanced?.tablet?.flex?.alignItems ? `align-items: ${styles.advanced.tablet.flex.alignItems};` : ''}
    ${styles?.style?.tablet?.background?.color ? `background-color: ${styles.style.tablet.background.color ?? 'transparent'};` : ''}
    ${styles?.style?.tablet?.background?.image?.size ? `background-size: ${styles.style.tablet.background.image.size};` : ''}
    ${styles?.style?.tablet?.background?.image?.repeat ? `background-repeat: ${styles.style.tablet.background.image.repeat};` : ''}
    ${styles?.style?.tablet?.background?.image?.position ? `background-position: ${styles.style.tablet.background.image.position};` : ''}
    ${styles?.style?.tablet?.background?.image?.url ? `background-image: url('${styles.style.tablet.background.image.url}');` : ''}
    ${styles?.advanced?.tablet?.flex ? 'display: flex;' : ''}
    ${styles?.layout?.tablet?.height && styles.layout.tablet.height !== '-1' ? `height: ${styles.layout.tablet.height};` : ''}
  `.trim().replace(/\s+/g, ' ');

  const desktopStyles = `
    ${styles?.advanced?.desktop?.flex?.alignItems ? `align-items: ${styles.advanced.desktop.flex.alignItems};` : ''}
    ${styles?.style?.desktop?.background?.color ? `background-color: ${styles.style.desktop.background.color ?? 'transparent'};` : ''}
    ${styles?.style?.desktop?.background?.image?.size ? `background-size: ${styles.style.desktop.background.image.size};` : ''}
    ${styles?.style?.desktop?.background?.image?.repeat ? `background-repeat: ${styles.style.desktop.background.image.repeat};` : ''}
    ${styles?.style?.desktop?.background?.image?.position ? `background-position: ${styles.style.desktop.background.image.position};` : ''}
    ${styles?.style?.desktop?.background?.image?.url ? `background-image: url('${styles.style.desktop.background.image.url}');` : ''}
    ${styles?.advanced?.desktop?.flex ? 'display: flex;' : ''}
    ${styles?.layout?.desktop?.height && styles.layout.desktop.height !== '-1' ? `height: ${styles.layout.desktop.height};` : ''}
  `.trim().replace(/\s+/g, ' ');

  const mobileMediaQuery = mobileStyles ? `
    .section-${sysId} {
      ${mobileStyles}
    }
  ` : '';

  const tabletMediaQuery = tabletStyles ? `
    @media (min-width: 768px) {
      .section-${sysId} {
        ${tabletStyles}
      }
    }
  ` : '';

  const desktopMediaQuery = desktopStyles ? `
    @media (min-width: 992px) {
      .section-${sysId} {
        ${desktopStyles}
      }
    }
  ` : '';

  const hasStyles = mobileMediaQuery || tabletMediaQuery || desktopMediaQuery;

  return (
    <div className={`section-${sysId}`}>
      <div className={[containerClass].join(' ')}>
        {children}
      </div>
      {hasStyles && (
      <style jsx>
        {`
          ${mobileMediaQuery}
          ${tabletMediaQuery}
          ${desktopMediaQuery}
        `}
      </style>)}
    </div>
  );
};
