import IconError from '@/assets/icons/error.svg';

import styles from './Alert.module.scss'
import { Button } from '@/components/content/Button/Button';

interface AlertProps {
  message: string,
  actionLabel: string,
  onClick?: () => void
}

const Alert = ({ message, actionLabel, onClick }: AlertProps) => (
  <div className={styles.Alert}>
    <div className={styles.AlertInner}>
      <div className={styles.AlertMessage}>
        {message}
        <button
          className={['btn', 'btn-primary'].join(' ')}
          onClick={onClick}
        >
          {actionLabel}
        </button>
      </div>
    </div>
  </div>
);

export default Alert;