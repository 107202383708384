import { SplideSlider } from "@/components/base";
import { SplideSlide } from '@splidejs/react-splide';
import ContentLoader from 'react-content-loader';
import { HeroStylesConfig, TravelPackageItem as TravelPackageItemDefaultProps, TravelPackageCategoryGroup as TravelPackageCategoryGroupDefaultProps, TravelPackagerFilter } from "@incarail/killa-common";
import { TravelPackagePot } from "../TravelPackagePot/TravelPackagePot";

import styles from "../TravelPackages.module.scss"

interface TravelPackageCarouselDefaultProps {
  travelPackageCategories: TravelPackageCategoryGroupDefaultProps[] | null,
  travelPackages: TravelPackageItemDefaultProps[] | null,
  travelPackageFilter: TravelPackagerFilter,
  isLoading: boolean,
}

const TravelPackagesCarouselLoader = () => (
  <>
    <ContentLoader 
      uniqueKey={"travel_carousel_image".split(' ').join().toLowerCase().replace(/,/g, '')}
      speed={2}
      width={288}
      height={275}
      viewBox="0 0 288 275"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="288" height="275" />
    </ContentLoader>
    <ContentLoader 
      uniqueKey={"travel_carousel_headline".split(' ').join().toLowerCase().replace(/,/g, '')}
      speed={2}
      width={288}
      height={60}
      viewBox="0 0 288 60"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="288" height="60" />
    </ContentLoader>
    <ContentLoader 
      uniqueKey={"travel_carousel_rating".split(' ').join().toLowerCase().replace(/,/g, '')}
      speed={2}
      width={288}
      height={25}
      viewBox="0 0 288 25"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="288" height="25" />
    </ContentLoader>
    <ContentLoader 
      uniqueKey={"travel_carousel_pricing".split(' ').join().toLowerCase().replace(/,/g, '')}
      speed={2}
      width={288}
      height={45}
      viewBox="0 0 288 45"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="288" height="45" />
    </ContentLoader>
  </>
);

export const TravelPackageCarousel = ({ travelPackageCategories, travelPackages, travelPackageFilter, isLoading }: TravelPackageCarouselDefaultProps) => {

  const defaultStyles: HeroStylesConfig = {
    hero: {
      arrows: true,
      arrowPath: '',
      autoplay: false,
      autoHeight: false,
      autoWidth: false,
      breakpoints: {
        1699: {
          perPage: 5,
          perMove: 5,
        },
        1599: {
          perPage: 4,
          perMove: 4,
        },
        991: {
          pagination: false,
          perPage: 3,
          perMove: 3,
        },
        767: {
          pagination: false,
          perPage: 2,
          perMove: 2,
        }
      },
      classes: {
        arrows: 'splide__arrows ir-arrows',
        arrow: 'splide__arrow ir-arrow',
        prev: 'splide__arrow--prev ir-prev',
        next: 'splide__arrow--next ir-next',
        pagination: 'splide__pagination ir-pagination',
        page: 'splide__pagination__page ir-dot',
      },
      direction: 'ltr',
      drag: false,
      focus: 0,
      gap: 15,
      height: '100%',
      interval: 5000,
      keyboard: false,
      lazyLoad: false,
      noDrag: 'input, textarea, .no-drag',
      padding: 0,
      pagination: false,
      pauseOnFocus: true,
      pauseOnHover: true,
      perPage: 6,
      perMove: 6,
      preloadPages: 1,
      releaseWheel: false,
      rewind: true,
      speed: 400,
      start: 0,
      type: 'slide',
      wheel: false,
      width: '100%',
    }
  }

  const renderTravelPackageLoading = () => (
    <div className={styles.TravelPackageCarouselLoading}>
      <div className={[styles.TravelPackageItem].join(' ')}>
        <TravelPackagesCarouselLoader />
      </div>
      <div className={[styles.TravelPackageItem].join(' ')}>
        <TravelPackagesCarouselLoader />
      </div>
      <div className={[styles.TravelPackageItem].join(' ')}>
        <TravelPackagesCarouselLoader />
      </div>
      <div className={[styles.TravelPackageItem].join(' ')}>
        <TravelPackagesCarouselLoader />
      </div>
    </div>
  )


  return (
    (!isLoading && Array.isArray(travelPackages) && travelPackageCategories) ? (
        <div className={styles.TravelPackageCarousel}>
          <SplideSlider
            className={[styles.Hero].join(' ')}
            {...defaultStyles.hero}
          >
            {travelPackages
              .filter((tp: TravelPackageItemDefaultProps) => 
                !travelPackageFilter || 
                tp.travelPackageCategories.some((c: string) => c.toString() === travelPackageFilter.defaultCategoryId)
              ).map((tp: any) =>(
              <SplideSlide key={tp.travelPackageName}>
                <TravelPackagePot {...tp} />
              </SplideSlide>
            ))}
          </SplideSlider>
        </div>
    ) : (
      renderTravelPackageLoading()
    )
  )

}