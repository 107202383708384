import DynamicRenderer from "@/components/DynamicRender";
import { Column as ColumnDefaultProps, columnConfig } from "@incarail/killa-common";


export const Column = (props: ColumnDefaultProps) => {
  const { fields: { contents, stylesConfig } } = props;

  const columnClass = columnConfig(stylesConfig);

  return (
    <div className={columnClass}>
      <DynamicRenderer contentArray={contents} skipSection={true} />
    </div>
  );
};