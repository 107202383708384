import { Grid as GridDefaultProps, Column as ColumnDefaultProps, StylesConfig } from "@incarail/killa-common";
import { Column } from "./Column";

import styles from "./Grid.module.scss"

export const Grid = (props: GridDefaultProps) => {
  const { contents, stylesConfig } = props;

  return (
    <div className={styles.Grid}>
      <div className="row">
        {(Array.isArray(contents) && contents.length > 0 ? contents : []).map((column: ColumnDefaultProps) => {
          return <Column {...column} key={column.sys.id} />
        })}
      </div>
    </div>
  );
};
