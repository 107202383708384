import { ImageBox as ImageBoxDefaultProps } from "@incarail/killa-common";

import styles from "./ImageBox.module.scss"

import { useInView } from "@/hooks";
import { ImageBoxFirst } from "./ImageBoxFirst";
import { ImageBoxSecond } from "./ImageBoxSecond";
import { ImageBoxThird } from "./ImageBoxThird";
import { useEffect, useMemo, useState } from "react";
import { Asset } from "contentful";
import { useRouter } from "next/router";
import { trackSelectPromotion, trackViewPromotion } from "@/utils";
import { SelectPromotionData } from "@/interfaces";

export const ImageBox = (props: ImageBoxDefaultProps) => {
  const { sys, name, headline, link, images, order, stylesConfig } = props;
  const { locale } = useRouter();

  const { ref: trackRef, isInView: isInViewTracking } = useInView(0.7);
  const { ref: animRef, isInView: isInViewAnimation } = useInView(0.1);

  const [viewedPromo, setViewedPromo] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  const imageBoxStyle = stylesConfig?.imagebox?.style ?? '1';

  const renderImageBoxContent = () => {
    switch (imageBoxStyle) {
      case '2':
        return <ImageBoxSecond {...props} />;
      case '3':
        return <ImageBoxThird {...props} />;
      case '1':
      default:
        return <ImageBoxFirst {...props} />;
    }
  }

  const imageIdList = useMemo(() => images.map((i: Asset) => i.sys.id), [images]);

  const promotionData = useMemo(() => ({
    promotionId: sys.id,
    promotionOrder: order ?? 0,
    promotionName: name ?? '',
    promotionImagesId: imageIdList.join(' :: '),
    promotionLink1: link,
    promotionType: sys.contentType.sys.id,
    promotionLang: locale ?? '',
  }), [locale, imageIdList]);

  useEffect(() => {
    if (isInViewTracking && !viewedPromo) {
      trackViewPromotion(promotionData);
      setViewedPromo(true);
    }
  }, [isInViewTracking, viewedPromo, promotionData]);


  useEffect(() => {
    if (isInViewAnimation && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [isInViewAnimation, hasAnimated]);  

  const handlePromoClick = () => {
    const promotionData: SelectPromotionData = {
      promotionId: sys.id,
      promotionOrder: order ?? 0,
      promotionName: name ?? '',
      promotionLinkSelected: link,
      promotionType: sys.contentType.sys.id,
      promotionLang: locale ?? '',
    }
    trackSelectPromotion(promotionData);
  };

  return link ? (
    <div
      ref={el => {
        trackRef.current = el;
        animRef.current = el;
      }}
      className={[
          styles.ImageBox, 
          imageBoxStyle === '1' ? styles.ImageBoxFirst : '', 
          imageBoxStyle === '2' ? styles.ImageBoxSecond : '',
          imageBoxStyle === '3' ? styles.ImageBoxThird : '',
          hasAnimated ? `fadeIn fadeIn-${order ?? 1}` : '',
        ].join(' ')}
      key={headline}
    >
      <a href={link} onClick={handlePromoClick} title={headline}>
        {renderImageBoxContent()}
      </a>
    </div>
  ) : (
    <div
      ref={el => {
        trackRef.current = el;
        animRef.current = el;
      }}
      className={[
        styles.ImageBox, 
        imageBoxStyle === '1' ? styles.ImageBoxFirst : '', 
        imageBoxStyle === '2' ? styles.ImageBoxSecond : '',
        imageBoxStyle === '3' ? styles.ImageBoxThird : '',
        hasAnimated ? `fadeIn fadeIn-${order ?? 1}}` : '',
      ].join(' ')}
      key={headline}
    >
      {renderImageBoxContent()}
    </div>
  );
};