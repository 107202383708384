import { documentToReactComponents, NodeRenderer } from '@contentful/rich-text-react-renderer';

import React, { FC, HTMLAttributes, ReactElement, ReactNode } from 'react';
import { RichContent as RichContentDefaultProps } from "@incarail/killa-common";
import { BLOCKS, INLINES, Block, Inline, Text } from '@contentful/rich-text-types';
import ContentItemRenderer from "@/components/ContentItemRender";

import styles from "./RichContent.module.scss"
import Image from 'next/image';
import { getContentfulImage } from '@/utils';
import { Asset } from 'contentful';

interface ComponentProps extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  className?: string;
}

type TextWithClasses = {
  text: string;
  classes: string;
};

type ContentfulNode = Block | Inline;

const extractTextAndClasses = (text: string): TextWithClasses => {
  const regex = /\[(.*?)\]\((.*?)\)/;
  const match = RegExp(regex).exec(text);

  if (match) {
    return { text: match[1], classes: match[2] };
  }
  return { text, classes: '' };
};

const createComponent = (tag: string): FC<ComponentProps> => {
  const Component: FC<ComponentProps> = ({ children, className = '', ...props }) => {
    return React.createElement(tag, { className, ...props }, children);
  };

  Component.displayName = `Dynamic${tag}`;

  return Component;
};

const renderTagElement = (tag: string): NodeRenderer => {
  const TagComponent = createComponent(tag);

  const elementRenderer = (node: any, children: ReactNode): ReactElement => {
    let finalText = "";
    let finalClasses = "";

    React.Children.forEach(children, child => {
      if (typeof child === 'string') {
        finalText += child;
      } else if (React.isValidElement(child) && child.type === 'a') {
        finalText += `<a href="${child.props.href}" target="_blank" rel="noopener noreferrer">${child.props.children}</a>`;
      }
    });

    const { text, classes } = extractTextAndClasses(finalText);
    finalClasses = classes;

    return <TagComponent className={finalClasses} dangerouslySetInnerHTML={{ __html: text }} />;
  };

  elementRenderer.displayName = `Render${tag}Element`;
  return elementRenderer;
};

const renderImageComponent = (): NodeRenderer => {
  const ImageComponent = (node: Block | Inline): ReactElement | null => {

    if (node.nodeType === BLOCKS.EMBEDDED_ASSET && 'target' in node.data && node.data.target?.fields) {
      const asset = node.data.target as Asset;
      const { title, file } = asset.fields;
      const imageAlt = title ?? "";

      const imageDetails = file.details.image;

      return (file && imageDetails) ? (
        <Image
          className="img-fluid d-block mx-auto"
          width={imageDetails?.width ?? 0}
          height={imageDetails?.height ?? 0}
          src={`${getContentfulImage(file, { q: 75, fm: 'JPG' })}`}
          alt={imageAlt}
        />
      ) : <></>;
    }

    return null;
  };

  ImageComponent.displayName = "ImageComponent";
  return ImageComponent;
};

const isBlock = (node: Block | Inline | Text): node is Block => {
  return Object.values(BLOCKS).includes(node.nodeType as BLOCKS);
};

const renderTable = (node: ContentfulNode): JSX.Element => {
  if (!isBlock(node) || node.nodeType !== BLOCKS.TABLE) {
    return <></>;
  }

  const rows = node.content.map((rowNode, rowIndex) => {
    if (!isBlock(rowNode)) return null;

    const cells = rowNode.content.map((cellNode, cellIndex) => {
      if (!isBlock(cellNode)) return null;

      const cellContent = cellNode.content.map((contentNode, contentIndex) => {
        if (isBlock(contentNode) && contentNode.nodeType === BLOCKS.PARAGRAPH) {
          return contentNode.content.map((textNode, textIndex) => {
            if (textNode.nodeType === 'text') {
              return <span key={textIndex}>{textNode.value}</span>;
            }
            return null;
          });
        }
        return null;
      }).filter(Boolean);

      return <td key={cellIndex}>{cellContent}</td>;
    }).filter(Boolean);

    return <tr key={rowIndex}>{cells}</tr>;
  }).filter(Boolean);

  return <div className="table-responsive"><table className="table table-bordered table-striped"><tbody>{rows}</tbody></table></div>;
};


const renderElementComponent = () => {
  const ElementComponent = (node: any) => {
    return (
      <ContentItemRenderer
        item={node.data.target}
        skipSection={true}
      />
    );
  };
  ElementComponent.displayName = "ElementComponent";
  return ElementComponent;
}

export const RichContent = (props: RichContentDefaultProps) => {
  const { content } = props;

  return (
    <div className={styles.RichContent}>
      {content && documentToReactComponents(content, {
        renderNode: {
          [BLOCKS.HEADING_1]: renderTagElement('h1'),
          [BLOCKS.HEADING_2]: renderTagElement('h2'),
          [BLOCKS.HEADING_3]: renderTagElement('h3'),
          [BLOCKS.HEADING_4]: renderTagElement('h4'),
          [BLOCKS.HEADING_5]: renderTagElement('h5'),
          [BLOCKS.HEADING_6]: renderTagElement('h6'),
          [BLOCKS.PARAGRAPH]: renderTagElement('p'),
          [BLOCKS.EMBEDDED_ENTRY]: renderElementComponent(),
          [BLOCKS.EMBEDDED_ASSET]: renderImageComponent(),
          [BLOCKS.TABLE]: renderTable,
        }
      })}
    </div>
  );
};
