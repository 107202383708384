import { Section } from './base';
import ContentfulComponents, { ContentfulComponents as ComponentsMap } from './content';
import { Entry } from 'contentful';

interface ContentItem {
  sys?: {
    contentType?: {
      sys?: {
        id?: keyof ComponentsMap;
      };
    };
    id?: string,
  };
  fields: {
    stylesConfig?: any;
    [key: string]: any;
  };
  [key: string]: any;
}

interface ContentItemRendererProps {
  item: Entry<ContentItem>,
  skipSection: boolean,
}

const ContentItemRenderer: React.FC<ContentItemRendererProps> = ({ item, skipSection }) => {
  const Component = ContentfulComponents[item.sys.contentType.sys.id] as React.ComponentType<any>;
  if (!Component) return null;
  
  const { sys, fields } = item;
  const componentProps = { sys, ...fields };

  return skipSection ? (
    <Component key={sys.id} {...componentProps} />
  ) : (
    <Section key={sys.id} sysId={sys.id} styles={fields.stylesConfig}>
      <Component {...componentProps} />
    </Section>
  );
};

export default ContentItemRenderer;
