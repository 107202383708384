import { FooterConfigProps, NavigationItem } from "@incarail/killa-common";

import styles from '@/styles/footer/BaseFooter.module.scss'
import { BaseFooterNavigationItem } from "./navigation/BaseFooterNavigationItem";
import Image from "next/image";


interface BaseFooterProps  {
  config?: FooterConfigProps,
}

declare var $zopim: any;

export const BaseFooter: React.FC<BaseFooterProps> = ({ config }) => {

  const { theme, logo, social, awards, allies, paymentMethods, contactInfo, legal, navigation } = config || {};

  return (
    <footer className={`${styles.BaseFooter} ${theme ? styles[`BaseFooter${theme}`] : ''}`}>
      <div className={styles.BaseFooterInner}>
        {logo &&
        <div className={[styles.FooterSection, styles.FooterSectionLogo].join(' ')}>
          <div className={styles.FooterSectionContent}>
            {(logo?.url && logo?.imageUrl) && <a href={logo.url} className={styles.FooterLogo}>
              <Image src={logo.imageUrl} width={logo.imageWidth} height={logo.imageHeight} className="img-fluid" alt="Inca Rail" />
            </a>}
            {(!logo?.url && logo?.imageUrl) &&
            <Image src={logo.imageUrl} width={logo.imageWidth} height={logo.imageHeight} className={styles.FooterLogo} alt="Inca Rail" />}
          </div>
        </div>}
        {social &&
        <div className={[styles.FooterSection, styles.FooterSectionSocial].join(' ')}>
          {social?.headline && 
          <div className={styles.FooterSectionTitle}>{social.headline}</div>}
          <div className={styles.FooterSectionContent}>
          {social.content?.map((s) => (
            <a href={s.url ?? '#'} title={s.name} target="_blank" rel="noreferrer" key={s.name} className={styles.FooterSocialLink}>
              <Image width={s.iconWidth ?? 0} height={s.iconHeight ?? 0} src={s.iconUrl ?? ''} alt={s.name ?? ''} className="img-fluid" />
            </a>
          ))}
          </div>
        </div>}
        {awards &&
        <div className={[styles.FooterSection, styles.FooterSectionAwards].join(' ')}>
          {awards?.headline && 
          <div className={styles.FooterSectionTitle}>{awards.headline}</div>}
          <div className={styles.FooterSectionContent}>
          {awards.content?.map((award) => (
            <Image src={award?.iconUrl ?? ''} width={award.iconWidth} height={award.iconHeight} className="img-fluid" alt={award.name ?? ''} key={award.name} />
          ))}
          </div>
        </div>}
        {allies &&
        <div className={[styles.FooterSection, styles.FooterSectionAllies].join(' ')}>
          {allies?.headline && 
          <div className={styles.FooterSectionTitle}>{allies.headline}</div>}
          <div className={styles.FooterSectionContent}>
          {allies.content?.map((ally) => (
            <Image src={ally.iconUrl ?? ''} width={ally.iconWidth ?? 0} height={ally.iconHeight ?? 0} className="img-fluid" alt={ally.name ?? ''} key={ally.name} />
          ))}
          </div>
        </div>}
        {contactInfo &&
        <div className={[styles.FooterSection, styles.FooterSectionContact].join(' ')}>
          {contactInfo?.headline && 
          <div className={styles.FooterSectionTitle}>{contactInfo.headline}</div>}
          <div className={styles.FooterSectionContent}>
          {contactInfo.content?.map((contact) => (
            <a
              className={styles.FooterSectionContactLink}
              href={contact.url ?? '#'}
              onClick={(evt: React.MouseEvent<HTMLAnchorElement>) => {
                if(contact.type === 'zendesk') {
                  evt.preventDefault();
                  if (typeof $zopim !== 'undefined') {
                    $zopim.livechat.window.show();
                  }
                }
              }}
              key={contact.name}
            >
              <Image alt={contact?.alt ?? contact.name ?? ''} width={contact.iconWidth ?? 0} height={contact.iconHeight ?? 0} src={contact.iconUrl ?? ''} className="img-fluid" />
              <span dangerouslySetInnerHTML={{ __html: contact.name || '' }}></span>
            </a>
          ))}
          </div>
        </div>}
        {legal &&
        <div className={[styles.FooterSection, styles.FooterSectionLegal].join(' ')}>
          {legal?.headline && 
          <div className={styles.FooterSectionTitle}>{legal.headline}</div>}
          <div className={styles.FooterSectionContent}>
          {legal.content?.map((ally) => (
            <a className={styles.FooterSectionLegalLink} href={ally.url ?? '#'} key={ally.name}>
              <span dangerouslySetInnerHTML={{ __html: ally.name ?? '' }}></span>
              <Image width={ally.iconWidth ?? 65} height={ally.iconHeight ?? 27} src={ally.iconUrl ?? ''} alt={ally.name ?? ''} key={ally.name} className="img-fluid" />
            </a>
          ))}
          </div>
        </div>}
        {paymentMethods &&
        <div className={[styles.FooterSection, styles.FooterSectionPayment].join(' ')}>
          {paymentMethods?.headline && 
          <div className={styles.FooterSectionTitle}>{paymentMethods.headline}</div>}
          <div className={styles.FooterSectionContent}>
          {paymentMethods.content?.map((payment) => (
            <Image width={payment.iconWidth ?? 0} height={payment.iconHeight ?? 0} src={payment.iconUrl ?? ''} alt={payment.name ?? ''} key={payment.name} />
          ))}
          </div>
        </div>}
        {navigation?.map((nav) => (
          <div className={[styles.FooterSectionNav, styles[`FooterSectionNav${nav.position}`]].join(' ')} key={nav.position}>
            <ul>
              {nav?.content?.fields?.children?.map((ni: NavigationItem) => (
                <BaseFooterNavigationItem key={ni.sys.id} {...ni} />
              ), [])}
            </ul>
          </div>
        ))}
        <div>

        </div>
      </div>
    </footer>
  );
};