import { useState } from "react";
import { useTranslation } from 'next-i18next';

import styles from "./Select.module.scss"
import CloseButton from '@/assets/icons/close.svg';
import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';
import { isMobile } from "@/utils/shared/app/isMobile";

import { useBookerContext } from '@/context/BookerContext';

interface SelectOptionsDefaultProps {
  label: string,
  value: string | number,
}

interface SelectDefaultProps {
  className?: string,
  label?: string,
  options?: SelectOptionsDefaultProps[]
  onSelect: (option: SelectOptionsDefaultProps) => void,
}

export const SelectList = (props: SelectDefaultProps) => {

  const { t } = useTranslation('form');
  const { deactivateBooker } = useBookerContext();

  const { label, className, options, onSelect } = props;
  const [openSelect, setOpenSelect] = useState(false);
  const [selected, setSelected] = useState<SelectOptionsDefaultProps>({
    label: t('roundTrip'),
    value: 0,
  });

  let closeTimer: NodeJS.Timeout;

  const handleClose = () => {
    if (isMobile) return;
    if (closeTimer) clearTimeout(closeTimer);
    closeTimer = setTimeout(() => {
      setOpenSelect(false);
      deactivateBooker();
    }, 1000);
  }

  const handleMouseEnter = () => {
    if (isMobile) return;
    if (closeTimer) clearTimeout(closeTimer);
  }

  return (
    <div
      className={[className ?? '', styles.Select, openSelect ? styles.SelectOpen : ''].join(' ').trim()}
      onMouseLeave={handleClose}
      onMouseEnter={handleMouseEnter}
    >
      <button
        type="button"
        className={['btn', styles.SelectButton].join(' ')}
        onClick={() => {
          setOpenSelect(!openSelect);
        }}
      >
        {selected?.label}
        <IconChevronDown />

      </button>
      <div className={styles.SelectBack}>
        <div
          className={styles.SelectContent}
        >
          <div className={styles.SelectContentHeader}>
            <div className={styles.SelectContentHeaderLabel}>
              {label ?? t('chooseOption')}
            </div>
            <button
              type="button"
              className={['btn', styles.SelectContentClose].join(' ')}
              onClick={() => {
                setOpenSelect(!openSelect);
              }}
            >
              <CloseButton />
            </button>
          </div>
          <div className={styles.SelectList}>
            <div className={styles.SelectListContent}>
              {options?.map((option) => (
                <div
                  key={option.value}
                  className={[styles.SelectListItem, option.value === selected?.value ? styles.SelectListItemActive : ''].join(' ').trim()}
                  onClick={() => {
                    setSelected(option);
                    onSelect(option)
                    setOpenSelect(false);
                  }}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};