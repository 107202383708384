import React from "react";
import { HeroSlide as HeroSlideComponent } from "@incarail/killa-common";
import { PromoImage } from "@/components/base";
import styles from "./HeroSlide.module.scss";
import { calculateAspectRatio } from "@/utils";
import { Asset } from "contentful";

interface HeroSlideProps {
  slide: HeroSlideComponent;
  withCaption: boolean;
  idx: number;
  onSelectPromotion: (link: string) => void;
}

export const HeroSlide = ({ slide, withCaption, idx, onSelectPromotion }: HeroSlideProps) => {
  const handleLinkClick = (link: string) => {
    onSelectPromotion(link);
  };

  const heroImages = slide.fields.images;

  const desktopPromoImage = heroImages.find((i: Asset) => i.fields.title === 'desktop');
  const desktopPromoImageWidth = desktopPromoImage?.fields.file.details.image?.width;
  const desktopPromoImageHeight = desktopPromoImage?.fields.file.details.image?.height;
  const desktopPromoAspect = (desktopPromoImageWidth  && desktopPromoImageHeight) ? calculateAspectRatio(desktopPromoImageWidth, desktopPromoImageHeight) : slide.fields.aspectRatio ? slide.fields.aspectRatio.xs : null;

  const mobilePromoImage = heroImages.find((i: Asset) => i.fields.title === 'mobile');
  const mobilePromoImageWidth = mobilePromoImage?.fields.file.details.image?.width;
  const mobilePromoImageHeight = mobilePromoImage?.fields.file.details.image?.height;
  const mobilePromoAspect = (mobilePromoImageWidth  && mobilePromoImageHeight) ? calculateAspectRatio(mobilePromoImageWidth, mobilePromoImageHeight) : slide.fields.aspectRatio ? slide.fields.aspectRatio.lg : null;

  return (
    <>
      {!slide.fields.rightLink && (
        <a
          href={slide.fields.leftLink ?? '#'}
          onClick={() => handleLinkClick(slide.fields.leftLink ?? '#')}
          className={[`HeroSlide-${slide.sys.id}`].join(' ')}
        >
          <PromoImage {...slide.fields} withCaption={withCaption} isLazy />
        </a>
      )}

      {slide.fields.rightLink && (
        <div className={[`HeroSlide-${slide.sys.id}`, styles.HeroDualPromoImage].join(' ')}>
          <PromoImage {...slide.fields} withCaption={withCaption} isLazy />
          <a
            href={slide.fields.leftLink ?? '#'}
            aria-label="left"
            onClick={() => handleLinkClick(slide.fields.leftLink ?? '#')}
          ></a>
          <a
            href={slide.fields.rightLink ?? '#'}
            aria-label="right"
            onClick={() => handleLinkClick(slide.fields.rightLink ?? '#')}
          ></a>
        </div>
      )}

      {/* Estilos dinámicos para manejar el aspect ratio */}
      <style jsx>{`
          .HeroSlide-${slide.sys.id} :global(img) {
            aspect-ratio: ${mobilePromoAspect ?? 'auto'};
          }
          @media (min-width: 992px) {
            .HeroSlide-${slide.sys.id} :global(img) {
              aspect-ratio: ${desktopPromoAspect ?? 'auto'};
            }
          }
        `}</style>
    </>
  );
};
