import { useRouter } from "next/router";
import styles from "./LocaleSelector.module.scss";
import { useTranslation } from 'next-i18next'
import IconFlagEs from '@/assets/icons/flags/es.svg';
import IconFlagEn from '@/assets/icons/flags/en.svg';
import IconFlagPt from '@/assets/icons/flags/pt.svg';
import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';
import { useState } from "react";

type LocaleSelectorProps = {
  pageUrls?: Record<string, string>;
};

const flagMap: Record<string, React.FC> = {
  en: IconFlagEn,
  es: IconFlagEs,
  pt: IconFlagPt,
} as const;

export const LocaleSelector = ({ pageUrls }: LocaleSelectorProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation('geo');

  const [showGeoList, setShowGeoList] = useState<boolean>(false);

  const translatedLocales = Object.keys(pageUrls || {}).map(key => ({
    key,
    label: t(key),
  }));

  const CurrentFlag = flagMap[locale || 'en'];

  return (
    <div
      className={[styles.LocaleSelector, showGeoList ? styles.LocaleSelectorOpen : ''].join(' ')}
      onMouseEnter={() => setShowGeoList(true)}
      onMouseLeave={() => setShowGeoList(false)}
    >
      <div className={styles.LocaleSelectorHead}>
        <CurrentFlag />
        {locale}
        <IconChevronDown />
      </div>
      <div className={styles.LocaleSelectorList}>
        {translatedLocales.map(({ key, label }) => {
          const FlagIcon = flagMap[key] || IconFlagEn;
          return (
            <a
              className={[styles.LocaleOption, locale === key && styles.LocaleOptionActive].join(' ')}
              href={`${pageUrls?.[key]}`}
              key={key}
              onClick={() => {
                setShowGeoList(false);
              }}
            >
              <FlagIcon />
              {label}
            </a>
          );
        })}
      </div>
    </div>
  );
};
