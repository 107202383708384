import { useState, useEffect, useCallback } from "react";
import { DEFAULT_LANGUAGE, TrainSchedule as TrainScheduleDefaultProps, TrainService } from "@incarail/killa-common";
import IconTrainDeparture from '@/assets/icons/train-departure.svg';
import IconTrainArrival from '@/assets/icons/train-arrival.svg';
import IconArrowOrientation from '@/assets/icons/arrow-orientation.svg';
import IconChevronDown from '@/assets/icons/arrows/chevron-down.svg';
import IconBimodal from '@/assets/icons/bimodal.svg';
import styles from "./TrainSchedule.module.scss"

import { useTranslation } from 'next-i18next';
import { useInView } from "@/hooks";
import { useRouter } from "next/router";
import { getSchedules } from "@/utils";
import Alert from "@/components/base/Alert/Alert";

type BenefitsState = {
  [id: number]: boolean;
};

export const TrainSchedule = (props: TrainScheduleDefaultProps) => {
  const [trainSchedules, setTrainSchedules] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const [activeSchedule, setActiveSchedule] = useState<number>(1);
  const [showBenefits, setShowBenefits] = useState<BenefitsState>({});

  const toggleBenefits = (id: number) => {
    setShowBenefits(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  }

  const { trainServices, weekDay } = props;
  const { ref, isInView } = useInView();
  const { locale } = useRouter();

  const { t } = useTranslation('trainSchedule');

  const fetchData = useCallback(async () => {
    const response = await getSchedules(locale ?? DEFAULT_LANGUAGE, weekDay);
    setErrorLoading(!!response?.errors);
    setTrainSchedules(response);
  }, [locale, weekDay]);


  useEffect(() => {
    if (isInView) {
      fetchData();
    }
  }, [isInView, fetchData]);

  useEffect(() => {
    if(trainSchedules) {
      setLoading(false);
    }
  }, [trainSchedules])

  return (
    <div className={[styles.TrainSchedule].join(' ')} ref={ref}>
      {(!loading && !errorLoading) && (
      <>
        <div className={styles.TrainScheduleHeader}>
          {trainSchedules.map((ts: any) => 
            <button
              key={`tab_${ts.travelOrientation}`}
              className={['btn', styles.TrainScheduleTab, ts.travelOrientation === activeSchedule ? styles.TrainScheduleTabActive : ''].join(' ')}
              onClick={() => {
                setActiveSchedule(ts.travelOrientation);
              }}
            >
              {ts.travelOrientation === 1 ? (
                <>
                <IconTrainDeparture
                  className={styles.TrainScheduleTabIcon}
                />
                <span>
                  {t('inboundTrain')}
                </span>
                </>
              ) : (
                <>
                  <IconTrainArrival
                    className={styles.TrainScheduleTabIcon}
                  />
                  <span>
                    {t('outboundTrain')}
                  </span>
                </>
              )}
            </button>
          )}
        </div>
        <div className={styles.TrainScheduleContent}>
        {trainSchedules.map((ts: any) => (
          <div key={`tabContent_${ts.travelOrientation}`} className={[styles.TrainScheduleSection, ts.travelOrientation === activeSchedule ? styles.TrainScheduleSectionActive : ''].join(' ')}>
            {ts.stations.map((station: any) => (
              <div key={`${station.departureStation.toLowerCase()} - ${station.arrivalStation.toLowerCase()}`}>
                <div className={styles.TrainScheduleSectionHead}>
                  <div className={styles.TrainScheduleSectionHeadline}>
                    {`${ts.travelOrientation === 1 ? t('inboundTrain') : t('outboundTrain')}`}: <strong><span>{`${station.departureStation}`}</span> {`${t('to')}`} <span>{`${station.arrivalStation}`}</span></strong>
                  </div>
                  <div className={styles.TrainScheduleSectionStops}>
                    <div className={[styles.TrainScheduleSectionStop, station.departureStationId !== "5" ? styles.TrainScheduleSectionStopDisabled : ''].join(' ')}>
                      {station.departureStationId === "5" ? 
                      <svg fill="none" height="14" viewBox="0 0 15 14" width="15" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7" fill="#053220" r="7">
                        </circle>
                        <path d="M8.80686 6.79737L6.14718 4.37578C5.95094 4.19711 5.95094 3.90742 6.14718 3.72875C6.34342 3.55008 6.66158 3.55008 6.85782 3.72875L9.87282 6.47386C10.0691 6.65253 10.0691 6.94221 9.87282 7.12089L6.85782 9.866C6.66158 10.0447 6.34342 10.0447 6.14718 9.866C5.95094 9.68732 5.95094 9.39764 6.14718 9.21897L8.80686 6.79737Z" fill="white">
                        </path>
                      </svg> :
                      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7" r="6.5" fill="white" stroke="#BEBEBE"/>
                      </svg>}
                      <span>Cusco</span>
                    </div>
                    <div className={styles.TrainScheduleSectionStop}>
                      <svg fill="none" height="14" viewBox="0 0 15 14" width="15" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7" fill="#053220" r="7">
                        </circle>
                        <path d="M8.80686 6.79737L6.14718 4.37578C5.95094 4.19711 5.95094 3.90742 6.14718 3.72875C6.34342 3.55008 6.66158 3.55008 6.85782 3.72875L9.87282 6.47386C10.0691 6.65253 10.0691 6.94221 9.87282 7.12089L6.85782 9.866C6.66158 10.0447 6.34342 10.0447 6.14718 9.866C5.95094 9.68732 5.95094 9.39764 6.14718 9.21897L8.80686 6.79737Z" fill="white">
                        </path>
                      </svg>
                      <span>Ollantaytambo</span>
                    </div>
                    <div className={styles.TrainScheduleSectionStop}>
                      <svg fill="none" height="14" viewBox="0 0 15 14" width="15" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7" fill="#053220" r="7">
                        </circle>
                        <path d="M8.80686 6.79737L6.14718 4.37578C5.95094 4.19711 5.95094 3.90742 6.14718 3.72875C6.34342 3.55008 6.66158 3.55008 6.85782 3.72875L9.87282 6.47386C10.0691 6.65253 10.0691 6.94221 9.87282 7.12089L6.85782 9.866C6.66158 10.0447 6.34342 10.0447 6.14718 9.866C5.95094 9.68732 5.95094 9.39764 6.14718 9.21897L8.80686 6.79737Z" fill="white">
                        </path>
                      </svg>
                      <span>Machu Picchu</span>
                    </div>
                  </div>
                </div>
                <div className={styles.TrainScheduleTable}>
                  <div className={styles.TrainScheduleTableHead}>
                    <div className={styles.TrainScheduleTableCol}>
                    {t('class')}
                    </div>
                    <div className={[styles.TrainScheduleTableCol, styles.TrainScheduleTimeInfo].join(' ')}>
                    {t('departure')}
                    </div>
                    <div className={styles.TrainScheduleTableCol}>
                    </div>
                    <div className={[styles.TrainScheduleTableCol, styles.TrainScheduleTimeInfo].join(' ')}>
                    {t('arrival')}
                    </div>
                    <div className={styles.TrainScheduleTableCol}>
                    {t('scheduleBenefits')}
                    </div>
                  </div>
                  {station?.services
                    .filter((service: any) => trainServices.some((trainService: TrainService) => trainService.fields.serviceId == service.serviceId))
                    .map((service: any) => 
                      service?.schedules
                        .filter((schedule: any) => schedule?.serviceName)
                        .map((schedule: any) => (
                          <div className={styles.TrainScheduleTableLine} key={`${schedule.serviceName}_${schedule.departureTime}_${schedule.arrivalTime}`}>
                            <div className={[styles.TrainScheduleTableCol, styles.TrainScheduleClass].join(' ')}>
                              {schedule.serviceName}
                              {service?.bimodal === 1 && (
                                <div className={styles.TrainScheduleBimodal}>{t('bimodalService')} <IconBimodal /></div>
                              )}
                            </div>
                            <div className={[styles.TrainScheduleTableCol, styles.TrainScheduleDepart].join(' ')}>
                              <div className={styles.TrainScheduleTimeInfo}>{schedule.departureTime}</div>
                              <div className={styles.TrainScheduleStationInfo}>{station.departureStation}</div>
                              {service?.bimodal === 1 && (
                                <div className={styles.TrainScheduleBimodal}>{t('bimodalService')} <IconBimodal /></div>
                              )}
                            </div>
                            <div className={[styles.TrainScheduleTableCol, styles.TrainScheduleArrow, 'text-center'].join(' ')}>
                              <IconArrowOrientation />
                            </div>
                            <div className={[styles.TrainScheduleTableCol, styles.TrainScheduleArrive].join(' ')}>
                              <div className={styles.TrainScheduleTimeInfo}>{schedule.arrivalTime}</div>
                              <div className={styles.TrainScheduleStationInfo}>{station.arrivalStation}</div>
                            </div>
                            {schedule.benefitsContent && (
                              <>
                                <button
                                  className={[styles.TrainScheduleSeeDetails, 'btn', 'btn-link'].join(' ')}
                                  key={schedule.benefitsId}
                                  onClick={() => toggleBenefits(schedule.benefitsId)}
                                >
                                  {t('seeDetails')}
                                  <IconChevronDown />
                                </button>
                                <div
                                  className={[styles.TrainScheduleTableCol, styles.TrainScheduleBenefits].join(' ')}
                                  style={{ display: showBenefits[schedule.benefitsId] ? 'block' : 'none' }}
                                  >
                                  {schedule.benefitsContent}
                                </div>
                              </>
                            )}
                          </div>
                        ))
                    )
                  }
                </div>
              </div>
            ))}
          </div>
         ))}
        </div>
      </>
      )}
      {(!loading && errorLoading) && (
        <div className={styles.TravelPackageEmpty}>
          <Alert
            message={t('errorAlert')}
            actionLabel={t('updateAgain')}
            onClick={() => {
              setErrorLoading(!errorLoading);
              setLoading(!loading);
              fetchData();
            }}
          />
        </div>
    )}
    </div>
  );
};